import './App.css';
import React from 'react';
import Routers from './components/Routers';


const App = () => {
  return (
     <div className="App">
      
        <Routers/>

    </div>

  );
};

export default App;