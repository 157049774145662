import React from 'react';
import { toast } from 'react-toastify';

const DeleteUser = ({ userId, userName, onCancel, onSuccess }) => {
  const handleDelete = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      const response = await fetch(`https://pos.farm2bag.com/api/v1/users/${userId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to delete user: ${response.status}`);
      }

      toast.success("User deleted successfully");
      onSuccess();
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error(error.message || "Error deleting user");
    }
  };

  return (
    <div className="text-center">
      <h2 className="text-xl mb-4">Delete User</h2>
      <p>Are you sure you want to delete user <strong>{userName}</strong>?</p>
      <div className="flex justify-between mt-6">
        <button onClick={onCancel} className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-purple-500"> Cancel</button>
        <button onClick={handleDelete} className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2" > Delete </button>
      </div>
    </div>
  );
};

export default DeleteUser;
