import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const EditUser = ({ user, onCancel, onSuccess }) => {
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    userName: user.userName,
    email: user.email,
    userCode: user.userCode,
    roleName: user.roleName,
    status: user.status,
    roleId: user.roleId,
    roleName: user.roleName,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCancel = () => {
    // Reset the form data to empty values
    setFormData({
      userName: "",
      email: "",
      userCode: "",
      roleName: "",
      status: "",
      roleId: "",
    });

    // Call the passed onCancel function if needed (for external handling)
    onCancel();
  };


  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token found in local storage');
          setError('No token found in local storage');
          return;
        }
  
        const response = await fetch('https://pos.farm2bag.com/api/v1/roles', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
  
        if (data.status === 200 && data.data.length === 0) {
          // If the data array is empty, display the message
          setError(data.message);  // Display the message
        } else {
          setRoles(data.data);  // Set the roles data if available
        }
  
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        setError(error.message);
      }
    };
  
    fetchRoles();
  }, []);
  



  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in local storage');
        return;
      }

      const response = await fetch(`https://pos.farm2bag.com/api/v1/users/${user.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`Failed to update user: ${response.status}`);
      }

      toast.success('User updated successfully');
      onSuccess();
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error(error.message || 'Error updating user');
    }
  };

  return (
    <div className="bg-orange-100 rounded-lg shadow-lg p-8 w-full max-w-md">
      <h2 className="text-3xl font-extrabold text-purple-600 text-center mb-8 tracking-tight leading-tight">
        Edit User
      </h2>
      {error && <p className="text-red-500 text-center">{error}</p>}
      <div className="space-y-6">
        <div className="flex items-center space-x-4">
          <label className="text-sm font-medium text-gray-700 w-24">
            Username:
          </label>
          <input
            type="text"
            name="userName"
            value={formData.userName}
            onChange={handleChange}
            className={`flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 
          ${formData.userName ? 'focus:ring-green-500 border-gray-300' : 'border-red-500'}`}
          />
        </div>

        <div className="flex items-center space-x-4">
          <label className="text-sm font-medium text-gray-700 w-24">
            Email:
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={`flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 
          ${formData.email ? 'focus:ring-green-500 border-gray-300' : 'border-red-500'}`}
          />
        </div>

        <div className="flex items-center space-x-4">
          <label className="text-sm font-medium text-gray-700 w-24">
            Password:
          </label>
          <input
            type="password"
            name="password"
            placeholder='Enter New Password'
            value={formData.password}
            onChange={handleChange}
            className={`flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 
          ${formData.password ? 'focus:ring-green-500 border-gray-300' : 'border-red-500'}`}
          />
        </div>

        <div className="flex items-center space-x-4">
          <label className="text-sm font-medium text-gray-700 w-24">
            Role:
          </label>
          <select
            name="roleId"
            value={formData.roleId}
            onChange={handleChange}
            className={`flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 
          ${formData.roleId ? 'focus:ring-green-500 border-gray-300' : 'border-red-500'}`}
          >
            <option value="">Select Role</option>
            {roles.map((role) => (
              <option key={role.roleId} value={role.roleId}>
                {role.roleName}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mt-6 flex justify-between space-x-4">
        <button
          onClick={handleCancel}
          className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
        >
          Update
        </button>
      </div>
    </div>

  );
};

export default EditUser;