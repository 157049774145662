import React, { useState } from 'react';
import SalesReport from '../components/SalesReport';
import StockReport from '../components/StockReport';
import SalesReturn from '../components/SalesReturn';

const Report = () => {
  const [selectedReport, setSelectedReport] = useState('sales'); // Default to Sales Report

  // Function to render the selected report
  const renderReport = () => {
    switch (selectedReport) {
      case 'sales':
        return <SalesReport />;
      case 'stock':
        return <StockReport />;
      case 'salesReturn':
        return <SalesReturn />;
      default:
        return <div>Please select a report type</div>;
    }
  };

  return (
    <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
      {/* Report Selection Tabs */}
      <div className="flex justify-center mb-6">
        {/* Sales Report Button */}
        <button
          onClick={() => setSelectedReport('sales')}
          className={`px-6 py-2 mx-2 rounded-md text-white font-semibold transition duration-300 ease-in-out ${selectedReport === 'sales' ? 'bg-blue-600' : 'bg-gray-400 hover:bg-gray-500'
            }`}
        >
          Sales Report
        </button>

        {/* Stock Report Button */}
        <button
          onClick={() => setSelectedReport('stock')}
          className={`px-6 py-2 mx-2 rounded-md text-white font-semibold transition duration-300 ease-in-out ${selectedReport === 'stock' ? 'bg-blue-600' : 'bg-gray-400 hover:bg-gray-500'
            }`}
        >
          Stock Report
        </button>

        {/* Sales Return Report Button */}
        <button
          onClick={() => setSelectedReport('salesReturn')}
          className={`px-6 py-2 mx-2 rounded-md text-white font-semibold transition duration-300 ease-in-out ${selectedReport === 'salesReturn' ? 'bg-blue-600' : 'bg-gray-400 hover:bg-gray-500'
            }`}
        >
          Sales Return Report
        </button>
      </div>

      {/* Render Selected Report */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        {renderReport()}
      </div>
    </div>
  );
};

export default Report;
