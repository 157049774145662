import React, { useEffect, useState } from 'react';

const LoginLogs = () => {
    const [loginLogs, setLoginLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const token = localStorage.getItem('token');

    const [currentPage, setCurrentPage] = useState(1);
    const logsPerPage = 5;

    const totalLogs = loginLogs.length;
    const totalPages = Math.ceil(totalLogs / logsPerPage);
    const startIndex = (currentPage - 1) * logsPerPage;
    const currentLogs = loginLogs.slice(startIndex, startIndex + logsPerPage);

    useEffect(() => {
        if (!token) {
            setError('Token not found in local storage');
            setLoading(false);
            return;
        }

        fetch('https://pos.farm2bag.com/api/v1/loginLogs', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    setLoginLogs(data.data);
                } else {
                    setError(data.message);
                }
                setLoading(false);
            })
            .catch((err) => {
                setError('Failed to fetch login logs');
                setLoading(false);
            });
    }, [token]);


    const goToPreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="p-2">
            <h1 className="text-4xl font-extrabold mb-1 text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 p-2 rounded-lg shadow-lg text-center">
                Login Logs </h1>
            <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-lg overflow-hidden">
                <thead>
                    <tr className="bg-blue-600 text-white">
                        <th className="px-4 py-3">UserCode</th>
                        <th className="px-4 py-3">Username</th>
                        <th className="px-4 py-3">Email</th>
                        <th className="px-4 py-3">Role</th>
                        <th className="px-4 py-3">Login Time</th>
                        <th className="px-4 py-3">Logout Time</th>
                    </tr>
                </thead>
                <tbody>
                    {currentLogs.map((log) => (
                        <tr key={log.id} className="hover:bg-blue-100 even:bg-gray-50 odd:bg-white">
                            <td className="px-4 py-2 border text-gray-700">{log.userCode}</td>
                            <td className="px-4 py-2 border text-gray-700">{log.userName}</td>
                            <td className="px-4 py-2 border text-gray-700">{log.email}</td>
                            <td className="px-4 py-2 border text-gray-700">{log.role}</td>
                            <td className="px-4 py-2 border text-gray-700">{new Date(log.loginTime).toLocaleString()}</td>
                            <td className="px-4 py-2 border text-gray-700">
                                {log.logoutTime ? new Date(log.logoutTime).toLocaleString() : "User still logged in"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="flex justify-between items-center mt-4">
                <button
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-gray-400"
                    onClick={goToPreviousPage}
                    disabled={currentPage === 1}
                > Previous </button>
                <span className="text-gray-700"> Page {currentPage} of {totalPages} </span>
                <button
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-gray-400"
                    onClick={goToNextPage}
                    disabled={currentPage === totalPages}
                > Next</button>
            </div>
        </div>
    );
};

export default LoginLogs;