import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';
import dayjs from 'dayjs';
import '../scss/StocksVsSales.scss';
const ITEMS_PER_PAGE = 10;

const StocksVsSales = () => {
  const [warehouses, setWarehouses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [soldQuantities, setSoldQuantities] = useState([]);
  const [stockLogs, setStockLogs] = useState([]);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetch('https://pos.farm2bag.com/api/v1/warehouse', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 200 && data.data) {
            setWarehouses(data.data);
            // Auto-select the first warehouse
            if (data.data.length > 0) setSelectedWarehouseId(data.data[0].id);
          } else {
            setError('Failed to fetch warehouse data.');
          }
        })
        .catch(err => setError(err.message));

      fetch('https://pos.farm2bag.com/api/v1/categories/getAll/master', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 200 && data.data) {
            setCategories(data.data);
          } else {
            setError('Failed to fetch categories data.');
          }
        })
        .catch(err => setError(err.message));

      const today = dayjs();
      setFromDate(today);
      setToDate(today);

      setLoading(false);
    } else {
      setError('No token found in local storage');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchSoldQuantities = async () => {
      const token = localStorage.getItem('token');
      try {
        const url = new URL('https://pos.farm2bag.com/api/v1/billingItems/status');

        if (selectedWarehouseId) url.searchParams.append('warehouseId', selectedWarehouseId);
        if (selectedCategory) url.searchParams.append('categoryId', selectedCategory);
        if (fromDate) url.searchParams.append('fromDate', dayjs(fromDate).format('YYYY-MM-DD'));
        if (toDate) url.searchParams.append('toDate', dayjs(toDate).format('YYYY-MM-DD'));

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        if (response.ok) {
          setSoldQuantities(data.data);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Error fetching sold quantities:', error);
      }
    };

    fetchSoldQuantities();
  }, [selectedWarehouseId, selectedCategory, fromDate, toDate]);

  useEffect(() => {
    const fetchStockLogs = async () => {
      const token = localStorage.getItem('token');
      try {
        const url = new URL('https://pos.farm2bag.com/api/v1/stocksLogs');

        if (selectedWarehouseId) url.searchParams.append('warehouseId', selectedWarehouseId);
        if (selectedCategory) url.searchParams.append('productCategoryId', selectedCategory);
        if (fromDate) url.searchParams.append('fromDate', dayjs(fromDate).format('YYYY-MM-DD'));
        if (toDate) url.searchParams.append('toDate', dayjs(toDate).format('YYYY-MM-DD'));

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        if (response.ok) {
          setStockLogs(data.data);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Error fetching stock logs:', error);
      }
    };

    fetchStockLogs();
  }, [selectedWarehouseId, selectedCategory, fromDate, toDate]);

  useEffect(() => {
    setCurrentPage(1); // Reset to first page on filter change
  }, [selectedWarehouseId, selectedCategory, fromDate, toDate]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Merged data (stock and sales)
  const mergedData = stockLogs
    .map(stock => {
      const sold = soldQuantities.find(s => s.productId === stock.productId);
      return {
        productName: stock.productName,
        stockQuantity: stock.quantity,
        soldQuantity: sold ? sold.quantity : 0,
        totalPrice: sold ? sold.totalPrice : 0,
        unit: sold ? sold.unit : '',
      };
    })
    .filter(item => item.stockQuantity > 0 || item.soldQuantity > 0);

  // Filtered merged data based on the search term
  const filteredData = mergedData.filter(item =>
    item.productName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination calculation
  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(prev => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(prev => prev - 1);
  };


  return (
    <div>
      <h1 className="title">Inventory & Sales Performance</h1>
      <div className="seconddiv flex justify-evenly rounded-lg mb-0">
        <div>
          <label className="text-white text-sm mb-1 block">Select Warehouse</label>
          <select
            onChange={(e) => setSelectedWarehouseId(e.target.value)}
            className="select-style"
          >
            {warehouses.length > 0 ? (
              warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name} - {warehouse.city}, {warehouse.country}
                </option>
              ))
            ) : (
              <option>No warehouses available</option>
            )}
          </select>
        </div>

        <div>
          <label className="text-white text-sm mb-1 block">Select Category</label>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="select-style"
          >
            <option value="">Select Category</option>
            {categories.length > 0 && categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.categoryName} ({category.productCount} products)
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="text-white text-sm mb-1 block">Select Date Range</label>
          <DatePicker
            value={fromDate}
            placeholder="From Date"
            onChange={(date) => setFromDate(date)}
            className="date-picker-style"
          />
          <DatePicker
            value={toDate}
            placeholder="To Date"
            onChange={(date) => setToDate(date)}
            className="date-picker-style"
          />
        </div>

        <div>
          <label className="text-white text-sm mb-1 block">Search Product</label>
          <input
            type="text"
            placeholder="Search by product name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="input-style"
          />
        </div>

        <div className="mt-1 flex items-center space-x-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`px-4 py-2 text-sm font-medium rounded-md shadow ${currentPage === 1
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-blue-500 text-white hover:bg-blue-600 focus:ring-2 focus:ring-blue-400 focus:outline-none"
              }`}
          >
            Previous
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 text-sm font-medium rounded-md shadow ${currentPage === totalPages
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-blue-500 text-white hover:bg-blue-600 focus:ring-2 focus:ring-blue-400 focus:outline-none"
              }`}
          >
            Next
          </button>
        </div>

      </div>

      {filteredData.length > 0 ? (
        <div>
          <div style={{ height: 500, width: "100%" }}>
            <ResponsiveContainer>
              <BarChart
                data={paginatedData}
                margin={{ bottom: 10, top: 50 }}
                style={{ backgroundColor: 'white' }}
              >
                <CartesianGrid stroke="#ccc" strokeDasharray="5 4" />
                <XAxis
                  dataKey="productName"
                  angle={-45}
                  textAnchor="end"
                  tick={(props) => {
                    const { x, y, payload } = props;
                    const truncatedProductName = payload.value.slice(0, 10);
                    return (
                      <text
                        x={x}
                        y={y}
                        textAnchor="middle"
                        fill="#666"
                        fontSize={12}
                        dy={10}
                      >
                        {truncatedProductName}
                      </text>
                    );
                  }}
                />
                <YAxis
                  tickFormatter={(value) => `${value}`}
                  label={{
                    value: 'Quantity',
                    angle: -90,
                    position: 'insideLeft',
                    fontSize: 14,
                    fill: 'black',
                    fontWeight: 'bold',
                  }}
                />
                <Tooltip
                  formatter={(value, name) => {
                    if (name === 'Stocks') return [`${value}`, 'Stock Quantity'];
                    if (name === 'Sales') return [`${value}`, 'Sales Quantity'];
                    return value;
                  }}
                  content={({ payload, label }) => {
                    if (payload && payload.length > 0) {
                      const { stockQuantity, soldQuantity, totalPrice, unit } = payload[0].payload;
                      return (
                        <div className="custom-tooltip">
                          <p className="label">Product: {label}</p>
                          <p className="stock-quantity">
                            <strong>Stock Quantity:</strong> {stockQuantity.toFixed(2)}
                          </p>
                          <p className="sales-quantity">
                            <strong>Total Sales Quantity:</strong> {soldQuantity}{unit}
                          </p>
                          <p className="sales-amount">
                            <strong>Total Sales Amount:</strong> ${totalPrice.toFixed(2)}
                          </p>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
                <Legend verticalAlign="top" height={36} />
                <Bar dataKey="stockQuantity" fill="rgba(7, 245, 71, 0.6)" name="Stocks" />
                <Bar dataKey="soldQuantity" fill="rgba(3, 69, 252, 0.6)" name="Sales" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <span className="text-sm text-gray-700">
            {currentPage} of {totalPages}
          </span>
        </div>
      ) : (
        <p>No data available for the selected filters.</p>
      )}
    </div>
  );

};

export default StocksVsSales;