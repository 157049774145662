import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import Searchicon from "../Assets/tableicon/Searchicon.png";
import 'react-toastify/dist/ReactToastify.css';

const Gst = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [isCreatePopupOpen, setCreatePopupOpen] = useState(false);
    const [isEditPopupOpen, setEditPopupOpen] = useState(false);
    const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
    const [newGstType, setNewGstType] = useState('');
    const [newGst, setNewGst] = useState('');
    const [newIgst, setNewIgst] = useState('');
    const [newCgst, setNewCgst] = useState('');
    const [newSgst, setNewSgst] = useState('');
    const [updateGstType, setUpdateGstType] = useState('');
    const [updateGst, setUpdateGst] = useState('');
    const [updateIgst, setUpdateIgst] = useState('');
    const [updateCgst, setUpdateCgst] = useState('');
    const [updateSgst, setUpdateSgst] = useState('');
    const [selectedGst, setSelectedGst] = useState(null);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [fieldErrors, setFieldErrors] = useState({
      gstType: false,
      igst: false,
      cgst: false,
      sgst: false,
    });
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
            const response = await fetch('https://pos.farm2bag.com/api/v1/gst', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const responseData = await response.json();
            setData(responseData.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data
        .filter(item => item.gstType && item.gstType.toLowerCase().includes(searchQuery.toLowerCase()))
        .slice(indexOfFirstItem, indexOfLastItem);

    const handleEdit = (gst) => {
        setSelectedGst(gst);
        setUpdateGstType(gst.gstType);
        setUpdateGst(gst.gst);
        setUpdateIgst(gst.igst);
        setUpdateCgst(gst.cgst);
        setUpdateSgst(gst.sgst);
        setEditPopupOpen(true);
    };

    const handleDelete = (gst) => {
        setSelectedGst(gst);
        setDeletePopupOpen(true);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleItemsPerPageChange = (e) => {
        setCurrentPage(1);
        setItemsPerPage(parseInt(e.target.value));
    };

    const handleCreateGst = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');
            
            // Check for token
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
    
            // Validate GST type selection
            if (!newGstType) {
                toast.error('Please select a GST Type');
                return;
            }
    
            // Handle inter-state GST type
            if (newGstType === 'inter' && (!newIgst || isNaN(newIgst))) {
                toast.error('Please enter a valid IGST value');
                return;
            }
    
            // Handle intra-state GST type
            if (newGstType === 'intra' && (!newGst || isNaN(newGst))) {
                toast.error('Please enter a valid GST value');
                return;
            }
    
            // If intra-state GST is selected, split the gst value between cgst and sgst
            let cgstValue = 0;
            let sgstValue = 0;
            let igstValue = 0;
    
            if (newGstType === 'intra') {
                cgstValue = (parseFloat(newGst) / 2).toFixed(2);
                sgstValue = (parseFloat(newGst) / 2).toFixed(2);
            } else if (newGstType === 'inter') {
                igstValue = parseFloat(newIgst);
            }
    
            // Prepare payload for the API
            const payload = {
                gstType: newGstType,
                gst: newGst,
                igst: igstValue || 0,  // Set IGST, or 0 if not applicable
                cgst: cgstValue || 0,  // Set CGST, or 0 if not applicable
                sgst: sgstValue || 0,  // Set SGST, or 0 if not applicable
                createdBy: role
            };
    
            // Make the POST request
            const response = await fetch('https://pos.farm2bag.com/api/v1/gst', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
    
            // Handle the API response
            if (!response.ok) {
                throw new Error('Failed to create GST');
            }
    
            setCreatePopupOpen(false); // Close the popup after successful creation
            setNewGstType(''); // Reset form fields
            setNewGst('');
            setNewIgst('');
            setNewCgst('');
            setNewSgst('');
            fetchData(); // Refresh the GST data
            toast.success('GST created successfully');
        } catch (error) {
            console.error('Error creating GST:', error);
            toast.error('Error creating GST');
        }
    };
    
    

    const handleUpdateGst = async () => {
        try {
    
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');
            const id = selectedGst?.id;
    
            if (!token) {
                console.error('No token found in local storage');
                toast.error('Authentication token missing');
                return;
            }
    
            if (!id) {
                console.error('No GST ID found for update');
                toast.error('GST ID is missing');
                return;
            }
    

    
            // Safeguard: Ensure the values are strings before calling .trim()
            const gstType = (updateGstType || '').trim();
            const gst = (updateGst || '').trim();
            const igst = (updateIgst || '').toString().trim(); // Convert to string if not already
            const cgst = (updateCgst || '').toString().trim();
            const sgst = (updateSgst || '').toString().trim();
    
            if (!gstType || !gst) {
                toast.error('GST Type and GST value are required');
                return;
            }
    
            // GST type-specific validation
            if (gstType === 'inter' && (!igst || cgst || sgst)) {
                toast.error('For Inter GST, IGST is required and CGST/SGST should be 0');
                return;
            }
    
            if (gstType === 'intra' && (!cgst || !sgst || igst)) {
                toast.error('For Intra GST, CGST and SGST are required, and IGST should be 0');
                return;
            }
    
    
            // API Call
            const response = await fetch(`https://pos.farm2bag.com/api/v1/gst/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    gstType: gstType,
                    gst: gst,
                    igst: igst,
                    cgst: cgst,
                    sgst: sgst,
                    modifiedBy: role
                })
            });
    
    
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to update GST: ${errorText}`);
            }
    
    
            // Reset form and state after success
            setEditPopupOpen(false);
            setUpdateGstType('');
            setUpdateGst('');
            setUpdateIgst('');
            setUpdateCgst('');
            setUpdateSgst('');
    
            fetchData();
            
            toast.success('GST updated successfully');
        } catch (error) {
            console.error('Error updating GST:', error);
            toast.error('Error updating GST');
        }
    };
    
    
    
    



    const handleDeleteGst = async () => {
        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');
            if (!token) {
                console.error('No token found in local storage');
                return;
            }
            const id = selectedGst.id;
            const response = await fetch(`https://pos.farm2bag.com/api/v1/gst/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ modifiedBy: role })
            });
            if (!response.ok) {
                throw new Error('Failed to delete Gst');
            }
            setDeletePopupOpen(false);
            fetchData();
            toast.success('Gst deleted successfully');
        } catch (error) {
            console.error('Error deleting Gst:', error);
        }
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
            <div className="container mx-auto">
                <div className="mb-10">
                    <h1 className="font-extrabold text-3xl text-left text-gray-500">Gst</h1>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>
                <div className="flex items-center justify-between mb-5">
                    <div className="relative">
                        <input type="text" placeholder='search' value={searchQuery} onChange={handleSearch} className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500" />
                        <img src={Searchicon} alt="search" className="absolute left-3 top-3 w-4 h-4" />
                    </div>
                    <button onClick={() => setCreatePopupOpen(true)} style={{ backgroundColor: 'rgba(66, 255, 97, 1)' }} className="w-48 h-10 font-medium text-sm rounded-lg"> Create Gst </button>
                </div>

                <div className="rounded-lg overflow-hidden">
                    <table className="min-w-full bg-white border-gray-500 text-center">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="border-b border-gray-200 py-2">Gst Type</th>
                                <th className="border-b border-gray-200 py-2">Gst</th>
                                <th className="border-b border-gray-200 py-2">IGST</th>
                                <th className="border-b border-gray-200 py-2">CGST</th>
                                <th className="border-b border-gray-200 py-2">SGST</th>
                                <th className="border-b border-gray-200 py-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((gst, index) => (
                                <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-200"}>
                                    <td className="border-b border-gray-200 py-2">{gst.gstType}</td>
                                    <td className="border-b border-gray-200 py-2">{gst.gst}</td>
                                    <td className="border-b border-gray-200 py-2">{gst.igst}</td>
                                    <td className="border-b border-gray-200 py-2">{gst.cgst}</td>
                                    <td className="border-b border-gray-200 py-2">{gst.sgst}</td>
                                    <td className="border-b border-gray-200 py-2">
                                        <button onClick={() => handleEdit(gst)} className="mr-2 font-bold rounded">
                                            <FaRegEdit size={22} className="inline-block mr-1" />
                                        </button>
                                        <button onClick={() => handleDelete(gst)} className="text-white font-bold rounded">
                                            <IoMdTrash size={30} style={{ color: "red" }} className="inline-block mr-1" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="mt-4 flex justify-between items-center">
                    <div>
                        <span className="mr-2">Items per page:</span>
                        <select className="border border-gray-400 rounded p-1" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                        </select>
                    </div>
                    <div>
                        {data.length > 0 && (
                            <nav className="relative z-0 inline-flex shadow-sm">
                                <div>
                                    <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 disabled:opacity-50"> Previous </button>
                                    {[...Array(Math.ceil(data.length / itemsPerPage))].map((_, index) => (
                                        <button key={index} onClick={() => paginate(index + 1)} className={`-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${currentPage === index + 1 ? 'text-gray-700 bg-gray-100' : 'text-gray-500 hover:bg-gray-50'} focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}>
                                            {index + 1}
                                        </button>
                                    ))}
                                    <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(data.length / itemsPerPage)} className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 disabled:opacity-50"> Next </button>
                                </div>
                            </nav>
                        )}
                    </div>
                </div>

                {isCreatePopupOpen && (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-md">
      <h2 className="text-2xl font-semibold text-center mb-6">Create GST</h2>

      {errorMessage && (
        <div className="mb-4 text-red-500 text-sm text-center">{errorMessage}</div>
      )}

      {/* GST Type Dropdown */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">GST Type</label>
        <select
          value={newGstType}
          onChange={(e) => setNewGstType(e.target.value)}
          className={`w-full p-3 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 ${fieldErrors.gstType ? 'border-red-500' : ''}`}
        >
          <option value="" disabled>Select GST Type</option>
          <option value="intra">Intra-State (CGST + SGST)</option>
          <option value="inter">Inter-State (IGST)</option>
        </select>
      </div>

      {/* GST Input */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">GST</label>
        <input
          type="text"
          placeholder="Enter GST Percentage"
          value={newGst}
          onChange={(e) => {
            setNewGst(e.target.value);
            if (newGstType === "intra") {
              const splitValue = (parseFloat(e.target.value) / 2).toFixed(2);
              setNewCgst(splitValue);
              setNewSgst(splitValue);
              setNewIgst(0); // Reset IGST when intra-state is selected
            } else if (newGstType === "inter") {
              setNewIgst(parseFloat(e.target.value));
              setNewCgst(0); // Reset CGST
              setNewSgst(0); // Reset SGST
            }
          }}
          className={`w-full p-3 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 ${fieldErrors.gst ? 'border-red-500' : ''}`}
        />
      </div>

      {/* IGST Input */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">IGST</label>
        <input
          type="text"
          placeholder="Enter IGST"
          value={newIgst}
          onChange={(e) => setNewIgst(e.target.value)}
          className={`w-full p-3 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 ${fieldErrors.igst ? 'border-red-500' : ''}`}
          disabled={newGstType === "intra"}
        />
      </div>

      {/* CGST Input */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">CGST</label>
        <input
          type="text"
          placeholder="Enter CGST"
          value={newCgst}
          onChange={(e) => setNewCgst(e.target.value)}
          className={`w-full p-3 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 ${fieldErrors.cgst ? 'border-red-500' : ''}`}
          disabled={newGstType === "inter"}
        />
      </div>

      {/* SGST Input */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">SGST</label>
        <input
          type="text"
          placeholder="Enter SGST"
          value={newSgst}
          onChange={(e) => setNewSgst(e.target.value)}
          className={`w-full p-3 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400 ${fieldErrors.sgst ? 'border-red-500' : ''}`}
          disabled={newGstType === "inter"}
        />
      </div>

      {/* Button Section */}
      <div className="flex justify-end space-x-4">
        <button
          onClick={() => setCreatePopupOpen(false)}
          className="px-4 py-2 bg-gray-200 rounded-lg text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
        >
          Cancel
        </button>
        <button
          onClick={handleCreateGst}
          className="px-4 py-2 bg-blue-500 rounded-lg text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          Create
        </button>
      </div>
    </div>
  </div>
)}


{isEditPopupOpen && (
    <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Edit GST</h2>

            {/* Dropdown for selecting GST Type */}
            <select
                value={updateGstType}
                onChange={(e) => {
                    setUpdateGstType(e.target.value);
                    // Reset dependent fields when GST type changes
                    if (e.target.value === 'inter') {
                        setUpdateCgst('');
                        setUpdateSgst('');
                    } else {
                        setUpdateIgst('');
                    }
                }}
                className="w-full mb-2 p-2 border rounded"
            >
                <option value="">Select GST Type</option>
                <option value="intra">Intra</option>
                <option value="inter">Inter</option>
            </select>

            {/* GST input field */}
            <input
                type="text"
                placeholder="GST"
                value={updateGst}
                onChange={(e) => {
                    const gstValue = parseFloat(e.target.value);
                    setUpdateGst(e.target.value);

                    // Automatically update dependent fields based on GST type
                    if (updateGstType === 'intra') {
                        const halfGst = gstValue / 2;
                        setUpdateCgst(halfGst.toFixed(2));
                        setUpdateSgst(halfGst.toFixed(2));
                    } else if (updateGstType === 'inter') {
                        setUpdateIgst(gstValue.toFixed(2));
                    }
                }}
                className="w-full mb-2 p-2 border rounded"
            />

            {/* IGST input field - enabled only for Inter-state GST */}
            <input
                type="text"
                placeholder="IGST"
                value={updateIgst}
                onChange={(e) => setUpdateIgst(e.target.value)}
                className="w-full mb-2 p-2 border rounded"
                disabled={updateGstType === 'intra'} // Disable if Intra GST selected
            />

            {/* CGST input field - enabled only for Intra-state GST */}
            <input
                type="text"
                placeholder="CGST"
                value={updateCgst}
                onChange={(e) => setUpdateCgst(e.target.value)}
                className="w-full mb-2 p-2 border rounded"
                disabled={updateGstType === 'inter'} // Disable if Inter GST selected
            />

            {/* SGST input field - enabled only for Intra-state GST */}
            <input
                type="text"
                placeholder="SGST"
                value={updateSgst}
                onChange={(e) => setUpdateSgst(e.target.value)}
                className="w-full mb-4 p-2 border rounded"
                disabled={updateGstType === 'inter'} // Disable if Inter GST selected
            />

            <div className="flex justify-end">
                <button
                    onClick={() => setEditPopupOpen(false)}
                    className="mr-2 px-4 py-2 border rounded bg-gray-200 hover:bg-gray-300"
                >
                    Cancel
                </button>
                <button
    onClick={handleUpdateGst}
    className="px-4 py-2 border rounded bg-blue-500 text-white hover:bg-blue-600"
    disabled={isLoading} // Disable the button when loading
>
    {isLoading ? 'Updating...' : 'Update'}
</button>

            </div>
        </div>
    </div>
)}


                {isDeletePopupOpen && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg">
                            <h2 className="text-xl font-bold mb-4">Delete GST</h2>
                            <p>Are you sure you want to delete this GST?</p>
                            <div className="flex justify-end mt-4">
                                <button onClick={() => setDeletePopupOpen(false)} className="mr-2 px-4 py-2 border rounded bg-gray-200 hover:bg-gray-300">Cancel</button>
                                <button onClick={handleDeleteGst} className="px-4 py-2 border rounded bg-red-500 text-white hover:bg-red-600">Delete</button>
                            </div>
                        </div>
                    </div>
                )}

                <ToastContainer />
            </div>
        </div>
    );
};

export default Gst;