import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse } from "date-fns";

const StockCreate = ({ isVisible, onClose, onStockCreated }) => {
  const [categories, setCategories] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [newProduct, setNewProduct] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [newUnit, setNewUnit] = useState("");
  const [newQuantity, setNewQuantity] = useState("");
  const [newTrack, setNewTrack] = useState("");
  const [units, setUnits] = useState([]);
  const [expireDate, setExpireDate] = useState(null);
  const [manufactureDate, setManufactureDate] = useState(null);
  const [brand, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [purchasePrice, setPurchasePrice] = useState('');
  const [margin, setMargin] = useState('');
  const [transAndPackFees, setTransAndPackFees] = useState('');
  const [gstTax, setGstTax] = useState('');
  const [gstToggle, setGstToggle] = useState("Without GST");


  const [warehouseError, setWarehouseError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [productError, setProductError] = useState("");
  const [newProductError, setNewProductError] = useState("");
  const [newPriceError, setNewPriceError] = useState("");
  const [newUnitError, setNewUnitError] = useState("");
  const [newQuantityError, setNewQuantityError] = useState("");
  const [formError, setFormError] = useState("");
  const [exp_DateError, setExpireDateError] = useState("")
  const [maf_DateError, setMafDateError] = useState("");
  const [brandError, setBrandError] = useState("");
  const [purchasePriceError, setPurchasePriceError] = useState("");
  const [marginError, setMarginError] = useState("");
  const [transAndPackFeesError, setTransAndPackFeesError] = useState("");
  const [gstTaxError, setGstTaxError] = useState("");
  const [newTrackError, setNewTrackError] = useState("");




  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await fetch(
          "https://pos.farm2bag.com/api/v1/categories",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          setCategories(data.data);
        } else {
          console.error("Failed to fetch categories");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchWarehouses = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await fetch("https://pos.farm2bag.com/api/v1/warehouse", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (response.ok) {
          setWarehouses(data.data);
        } else {
          console.error("Failed to fetch warehouses");
        }
      } catch (error) {
        console.error("Error fetching warehouses:", error);
      }
    };

    const fetchUnits = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await fetch(
          "https://pos.farm2bag.com/api/v1/measurements",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          setUnits(data.data);
        } else {
          console.error("Failed to fetch units");
        }
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };
    const fetchBrands = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("No token found in local storage");
          return;
        }

        const response = await fetch(
          "https://pos.farm2bag.com/api/v1/brand",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          setBrands(data.data);
        } else {
          console.error("Failed to fetch Brands");
        }
      } catch (error) {
        console.error("Error fetching Brands:", error);
      }
    };

    if (isVisible) {
      fetchCategories();
      fetchWarehouses();
      fetchUnits();
      fetchBrands();
    }
  }, [isVisible]);

  const handleCategoryChange = async (categoryId) => {
    setSelectedCategory(categoryId);
    setCategoryError("");
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }
      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/products/category/${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSelectedProducts(data.data);
      } else {
        console.error("Failed to fetch products for category");
      }
    } catch (error) {
      console.error("Error fetching products for category:", error);
    }
  };


  useEffect(() => {
    const finalPrice = calculatePrice({
      purchasePrice,
      margin,
      transAndPackFees,
      gstTax,
    });
    setNewPrice(finalPrice.toFixed(2)); // Update `newPrice` with calculated value
  }, [purchasePrice, margin, transAndPackFees, gstTax]); // Trigger recalculation when these dependencies change

  const calculatePrice = ({ purchasePrice, margin, transAndPackFees, gstTax }) => {
    const purchasePriceValue = parseFloat(purchasePrice) || 0;
    const marginValue = parseFloat(margin) || 0;
    const feesValue = parseFloat(transAndPackFees) || 0;
    const gstValue = parseFloat(gstTax) || 0;

    // Calculate `withoutGSTTotal`
    const withoutGSTTotal = (purchasePriceValue * (marginValue / 100)) + feesValue;

    // Calculate `gstWithPriceValue`
    const gstWithPriceValue = purchasePriceValue * (gstValue / 100);

    // Final Price calculation
    const finalPrice = withoutGSTTotal + gstWithPriceValue + purchasePriceValue;

    return finalPrice; // Return the final price
  };


  const handleAddProduct = () => {

    if (!newProduct) {
      setNewProductError("Please select a product.");
    } else {
      setNewProductError("");
    }
    if (!newPrice) {
      setNewPriceError("Please enter the price.");
    } else {
      setNewPriceError("");
    }
    if (!newUnit) {
      setNewUnitError("Please select a unit.");
    } else {
      setNewUnitError("");
    }
    if (!newQuantity) {
      setNewQuantityError("Please enter the quantity.");
    } else {
      setNewQuantityError("");
    }
    if (!expireDate) {
      setExpireDateError("Please select the date");
    }
    else {
      setExpireDateError("");
    }
    if (!manufactureDate) {
      setMafDateError("Please select the date");
    }
    else {
      setMafDateError("");
    }
    if (!newTrack) {
      setNewTrack("Please select the track");
    }
    else {
      setNewTrack("");
    }
    if (!selectedBrand) {
      setBrandError("Please select a product.");
    } else {
      setBrandError("");
    }
    if (!purchasePrice) {
      setPurchasePriceError("Please enter a purchase price.");
    } else {
      setPurchasePriceError("");
    }



    if (!newProduct || !newPrice || !newUnit || !newQuantity || !expireDate || !manufactureDate || !selectedBrand || !purchasePrice) {
      return;
    }

    const selectedProduct = selectedProducts.find((p) => p.id === newProduct);
    const selectedUnit = units.find((unit) => unit.id === newUnit);

    if (!selectedProduct || !selectedUnit) {
      console.error("Selected product or unit not found.");
      return;
    }


    const product = {
      productId: newProduct,
      productName: selectedProduct.name,
      price: calculatePrice({
        purchasePrice,
        margin,
        transAndPackFees,
        gstTax
      }),
      unitId: newUnit,
      unitSymbol: selectedUnit.unitSymbol,
      quantity: parseFloat(newQuantity, 10),
      exp_Date: expireDate ? format(expireDate, "dd-MM-yyyy") : "N/A",
      maf_Date: manufactureDate
        ? format(manufactureDate, "dd-MM-yyyy")
        : "N/A",
      track: newTrack,
      brandId: selectedBrand,
      brandName: brand.find((b) => b.id === selectedBrand)?.brandName,
      purchasePrice: purchasePrice,
      margin: margin || 0,
      transAndPackFees: transAndPackFees || 0,
      gstTax: gstTax || 0

    };

    setNewProducts([...newProducts, product]);
    setNewProduct("");
    setNewPrice("");
    setNewUnit("");
    setNewQuantity("");
    setExpireDate("");
    setManufactureDate("");
    setNewTrack("");
    setFormError("");
    setSelectedBrand("");
    setPurchasePrice("");
    setMargin("");
    setTransAndPackFees("");
    setGstTax("");
  };


  const handleCreateStock = async () => {
    if (!selectedWarehouse) {
      setWarehouseError("Please select a warehouse.");
    }
    if (!selectedCategory) {
      setCategoryError("Please select a category.");
    }
    if (newProducts.length === 0) {
      setFormError("Please add at least one product.");
    } else {
      setFormError("");
    }

    if (!selectedWarehouse || !selectedCategory || newProducts.length === 0) {
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const role = localStorage.getItem("role");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      const response = await fetch("https://pos.farm2bag.com/api/v1/stocks", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          products: newProducts.map(({ unitSymbol, brandName, ...rest }) => rest),
          createdBy: role,
          modifiedBy: role,
          wareHouseId: selectedWarehouse,
        }),
      });

      if (response.ok) {
        resetForm();
        onClose();
        onStockCreated();
      } else {
        console.error("Failed to create stock");
      }
    } catch (error) {
      console.error("Error creating stock:", error);
    }
  };

  const resetForm = () => {
    setSelectedCategory("");
    setSelectedWarehouse("");
    setSelectedProducts([]);
    setNewProducts([]);
    setNewProduct("");
    setNewPrice("");
    setNewUnit("");
    setNewQuantity("");
    setManufactureDate("");
    setExpireDate("");
    setNewTrack("");
    setSelectedBrand("")


    // Reset all error states
    setWarehouseError("");
    setWarehouseError("");
    setCategoryError("");
    setProductError("");
    setNewProductError("");
    setNewPriceError("");
    setNewUnitError("");
    setNewQuantityError("");
    setFormError("");
    setMafDateError("");
    setExpireDateError("")
    setBrandError("");

  };

  const handleCancel = () => {
    resetForm();
    onClose();
  };

  const getWarehouseNameById = (id) => {
    const warehouse = warehouses.find((warehouse) => warehouse.id === id);
    return warehouse ? warehouse.name : "Unknown Warehouse";
  };

  if (!isVisible) return null;

  const handleWarehouseChange = (warehouseId) => {
    setSelectedWarehouse(warehouseId);
    setWarehouseError("");
  };
  const handleRemoveProduct = (index) => {
    const updatedProducts = [...newProducts];
    updatedProducts.splice(index, 1);
    setNewProducts(updatedProducts);
  };
  const handleExpireDateChange = (date) => {
    setExpireDate(date);
    setExpireDateError("")
  };
  const handleBrand = (brandId) => {
    setSelectedBrand(brandId);
    setBrandError("");
  }

  const handleManufactureDateChange = (date) => {
    setManufactureDate(date);
    setMafDateError(""); // Clear the error message when the date is selected
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80">
        <div className="bg-gray-300 p-6 rounded-lg shadow-lg w-10/12">
          <h2 className="text-2xl font-bold text-[#0f4f20] mb-4">Create New Stock Item</h2>

          <div className="mb-4 space-y-6">
            <div className="flex flex-wrap -mx-2">
              {/* Warehouse */}
              <div className="w-full sm:w-1/3 px-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Warehouse</label>
                <select
                  value={selectedWarehouse}
                  onChange={(e) => handleWarehouseChange(e.target.value)}
                  className="w-full px-2 py-2 border rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition duration-200"
                >
                  <option value="">Select Warehouse</option>
                  {warehouses.map((warehouse) => (
                    <option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </option>
                  ))}
                </select>
                {warehouseError && <p className="text-red-500 text-xs mt-1">{warehouseError}</p>}
              </div>

              {/* Category */}
              <div className="w-full sm:w-1/3 px-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Category</label>
                <select
                  value={selectedCategory}
                  onChange={(e) => handleCategoryChange(e.target.value)}
                  className="w-full px-2 py-2 border rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition duration-200"
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.categoryName}
                    </option>
                  ))}
                </select>
                {categoryError && <p className="text-red-500 text-xs mt-1">{categoryError}</p>}
              </div>

              {/* Product */}
              <div className="w-full sm:w-1/3 px-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Product</label>
                <select
                  value={newProduct}
                  onChange={(e) => {
                    setNewProduct(e.target.value);
                    setNewProductError("");
                  }}
                  className="w-full px-2 py-2 border rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition duration-200"
                >
                  <option value="">Select Product</option>
                  {selectedProducts.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>
                  ))}
                </select>
                {newProductError && <p className="text-red-500 text-xs mt-1">{newProductError}</p>}
              </div>


              {/* Brand */}
              <div className="w-full sm:w-1/3 px-2 mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Brand</label>
                <select
                  value={selectedBrand}
                  onChange={(e) => handleBrand(e.target.value)}
                  className="w-full px-2 py-2 border rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition duration-200"
                >
                  <option value="">Select Brand</option>
                  {brand.map((brand) => (
                    <option key={brand.id} value={brand.id}>
                      {brand.brandName}
                    </option>
                  ))}
                </select>
                {brandError && (
                  <p className="text-red-500 text-xs mt-1">{brandError}</p>
                )}
              </div>

              {/* Purchase Price*/}

              <div className="w-full sm:w-1/3 px-2 mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Purchase Price(₹)</label>
                <div className="flex items-center space-x-2">
                  {/* Input Field */}
                  <input
                    type="number"
                    min="0.1"
                    value={purchasePrice}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value >= 0) {
                        setPurchasePrice(value);
                        setPurchasePriceError("");
                      } else {
                        setPurchasePriceError("Negative value should not be entered");
                      }
                    }}
                    placeholder="Enter purchase price"
                    className="flex-1 px-2 py-2 border rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition duration-200"
                  />

                  {/* Toggle Button */}
                  <button
                    onClick={() => {
                      if (gstToggle === "Without GST") {
                        setGstToggle("With GST");
                        setGstTax("");
                      } else {
                        setGstToggle("Without GST");
                      }
                    }}
                    className="bg-green-500 text-white px-4 py-2 rounded-lg shadow hover:bg-green-600 transition duration-200 border border-2"
                  >
                    {gstToggle}
                  </button>
                </div>
                {/* Error Message */}
                {purchasePriceError && <p className="text-red-500 text-xs mt-1">{purchasePriceError}</p>}
              </div>

              {/* Margin */}

              <div className="w-full sm:w-1/3 px-2 mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Margin (%)</label>
                <input
                  type="number"
                  min="0"
                  value={margin !== undefined && margin !== null ? margin : 0}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value) || 0;
                    if (value >= 0) {
                      setMargin(value);
                      setMarginError("");
                    } else {
                      setMarginError("Value must be 0 or positive.");
                    }
                  }}
                  placeholder="Enter margin"
                  className="w-full px-2 py-2 border rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition duration-200"
                />
                {marginError && <p className="text-red-500 text-xs mt-1">{marginError}</p>}
              </div>

              {/* Trans and Pack Fees */}
              <div className="w-full sm:w-1/3 px-2 mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Trans & Pack Fees (₹)</label>
                <input
                  type="number"
                  min="0"
                  value={transAndPackFees !== undefined && transAndPackFees !== null ? transAndPackFees : 0}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value) || 0;
                    if (value >= 0) {
                      setTransAndPackFees(value);
                      setTransAndPackFeesError("");
                    } else {
                      setTransAndPackFeesError("Value must be 0 or positive.");
                    }
                  }}
                  placeholder="Enter trans & pack fees"
                  className="w-full px-2 py-2 border rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition duration-200"
                />
                {transAndPackFeesError && <p className="text-red-500 text-xs mt-1">{transAndPackFeesError}</p>}
              </div>

              {/* GST Tax */}
              <div className="w-full sm:w-1/3 px-2 mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">GST Tax (%)</label>
                <input
                  type="number"
                  min="0"
                  value={gstTax !== undefined && gstTax !== null ? gstTax : 0}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value) || 0;
                    if (value >= 0) {
                      setGstTax(value);
                      setGstTaxError("");
                    } else {
                      setGstTaxError("Value must be 0 or positive.");
                    }
                  }}
                  placeholder="Enter GST tax"
                  className={`w-full px-2 py-2 border rounded-lg transition duration-200 ${gstToggle === "With GST" ? "bg-gray-100 cursor-not-allowed" : "focus:outline-none focus:border-blue-500 focus:bg-blue-50"
                    }`}
                  disabled={gstToggle === "With GST"}
                />
                {gstTaxError && <p className="text-red-500 text-xs mt-1">{gstTaxError}</p>}
              </div>

              {/* New Price */}
              <div className="w-full sm:w-1/3 px-2 mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Price (₹)</label>
                <input
                  type="number"
                  value={newPrice} // Automatically set by calculation
                  readOnly // Prevent direct editing
                  placeholder="Calculated price"
                  className="w-full px-2 py-2 border rounded-lg focus:outline-none bg-gray-100 cursor-not-allowed"
                />
                {newPriceError && <p className="text-red-500 text-xs mt-1">{newPriceError}</p>}
              </div>


              {/* Unit */}
              <div className="w-full sm:w-1/3 px-2 mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Unit</label>
                <select
                  value={newUnit}
                  onChange={(e) => {
                    setNewUnit(e.target.value);
                    setNewUnitError("");
                  }}
                  className="w-full px-2 py-2 border rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition duration-200"
                >
                  <option value="">Select Unit</option>
                  {units.map((unit) => (
                    <option key={unit.id} value={unit.id}>
                      {unit.unitSymbol}
                    </option>
                  ))}
                </select>
                {newUnitError && <p className="text-red-500 text-xs mt-1">{newUnitError}</p>}
              </div>

              {/* Quantity */}
              <div className="w-full sm:w-1/3 px-2 mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Quantity</label>
                <input
                  type="number"
                  min="1"
                  value={newQuantity}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setNewQuantity(value);
                      setNewQuantityError("");
                    } else {
                      setNewQuantityError("Negative value should not be entered");
                    }
                  }}
                  placeholder="Enter quantity"
                  className="w-full px-2 py-2 border rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition duration-200"
                />
                {newQuantityError && <p className="text-red-500 text-xs mt-1">{newQuantityError}</p>}
              </div>

              {/* Track */}
              <div className="w-full sm:w-1/3 px-2 mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Tracking</label>
                <input
                  type="text"
                  value={newTrack}
                  onChange={(e) => setNewTrack(e.target.value)}
                  placeholder="Enter track"
                  className="w-full px-2 py-2 border rounded-lg focus:outline-none focus:border-blue-500 focus:bg-blue-50 transition duration-200"
                />
              </div>

              <div className="w-full sm:w-1/3 px-2 mt-4">
                <label
                  htmlFor="expireDate"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Manufacture Date

                </label>
                <DatePicker
                  selected={manufactureDate}
                  onChange={handleManufactureDateChange}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="dd-mm-yyyy"
                  className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {maf_DateError && (
                  <p className="text-red-500 text-xs">{maf_DateError}</p>
                )}

              </div>
              <div className="w-full sm:w-1/3 px-2 mt-4">
                <label
                  htmlFor="manufactureDate"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Expire Date
                </label>
                <DatePicker
                  selected={expireDate}
                  onChange={handleExpireDateChange}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="dd-mm-yyyy"
                  className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {exp_DateError && (
                  <p className="text-red-500 text-xs">{exp_DateError}</p>
                )}


              </div>

              {/* Add Product Button */}
              <div className="w-full sm:w-1/3 px-2 mt-10">
                <button
                  onClick={handleAddProduct}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 transition duration-200">
                  Add products to the table
                </button>
              </div>


            </div>
          </div>


          <div className="max-h-40 overflow-y-auto">
            <div className="mb-3  ">
              <h3 className="text-lg font-bold mb-1">Added Products</h3>
              <table className="min-w-full bg-white">
                <thead className="bg-green-400 ">
                  <tr>
                    <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                      Product Name
                    </th>
                    <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                      Brand Name
                    </th>


                    <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                      Purchase Price (Rs)
                    </th>

                    <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                      Margin (%)
                    </th>

                    <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                      Transport & Packing Fees (Rs)
                    </th>

                    <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                      Gst Tax (%)
                    </th>


                    <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                      Price (Rs)
                    </th>
                    <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                      Quantity (Q)
                    </th>
                    <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                      Manufacture Date
                    </th>

                    <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                      Expire Date
                    </th>
                    <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                      Track
                    </th>

                    <th className="py-2 px-4 border-b border-gray-200 text-left text-sm leading-4 font-medium text-gray-700 uppercase tracking-wider">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {newProducts.map((product, index) => (
                    <tr key={index}>
                      <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                        {product.productName}
                      </td>
                      <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                        {product.brandName}
                      </td>

                      <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                        {product.purchasePrice}
                      </td>

                      <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                        {product.margin}
                      </td>

                      <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                        {product.transAndPackFees}
                      </td>

                      <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                        {product.gstTax}
                      </td>

                      <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                        {product.price}
                      </td>

                      <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                        {product.quantity}
                        <span className="px-2 bg-yellow-300 rounded-md text-left">
                          {product.unitSymbol}
                        </span>{" "}
                      </td>
                      <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                        {product.maf_Date}
                      </td>
                      <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                        {product.exp_Date}
                      </td>
                      <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                        {product.track}
                      </td>
                      <td className="py-2 px-4 border-b border-gray-200 text-left text-sm">
                        <button
                          onClick={() => handleRemoveProduct(index)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {formError && <p className="text-red-500 text-xs">{formError}</p>}
          <div className="flex justify-between mt-4">
            <button
              onClick={handleCancel}
              className="bg-red-400 text-white px-4 py-2 rounded-lg mr-2 shadow-lg hover:bg-red-600 transition-colors duration-300"
            >
              Cancel
            </button>
            <button
              onClick={handleCreateStock}
              className="bg-gradient-to-r from-green-400 to-green-600 text-white px-4 py-2 rounded-lg shadow-lg hover:from-green-500 hover:to-green-700 transition-all duration-300 transform hover:scale-105"
            >
              Create Stock
            </button>
          </div>

        </div>
      </div>

    </>
  );
};

export default StockCreate;
