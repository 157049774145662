import React, { useState, useEffect } from 'react';
import Searchicon from "../Assets/tableicon/Searchicon.png";
import { FaRegEdit } from 'react-icons/fa';
import { IoMdTrash } from 'react-icons/io';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Models = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [modelName, setModelName] = useState('');
  const [status, setStatus] = useState('Enable');
  const [models, setModels] = useState([]); // State to store fetched models
  const [searchQuery, setSearchQuery] = useState(''); // State to store search query
  const [currentModelId, setCurrentModelId] = useState(null);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [modelToDelete, setModelToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // State for the current page
  const itemsPerPage = 7;


  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setModelName('');
    setStatus('Enable');
  };

  const toggleEditPopup = (model) => {
    setCurrentModelId(model.id);
    setModelName(model.pageName);
    setStatus(model.status);
    setIsEditPopupOpen(!isEditPopupOpen);
  };

  const handleCreate = async () => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');

    const data = {
      pageName: modelName,
      status: status,
      createdBy: role,
    };

    // Add a check for null or empty values
    if (!data.pageName || !data.status) {
      // Show a toast warning here (you'll need to implement this part)
      toast.warning('Please enter the model name.');
      return;
    }

    try {
      const response = await fetch('https://pos.farm2bag.com/api/v1/models', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast.success("Model created successfully");
        setIsPopupOpen(false);
        setModelName('');
        setStatus('Enable');
        fetchModels(); // Fetch the updated list after creating a new model
      } else {
        console.error('Failed to create model');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem('token');

    const data = {
      pageName: modelName,
      status: status,
    };

    // Add a check for null or empty values
    if (!data.pageName || !data.status) {
      // Show a toast warning here (you'll need to implement this part)
      toast.warning(' Please Enter the Model name.');
      return;
    }

    try {
      const response = await fetch(`https://pos.farm2bag.com/api/v1/models/${currentModelId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast.success("Model Updated successfully");
        setIsEditPopupOpen(false);
        setModelName('');
        setStatus('Enable');
        fetchModels(); // Fetch the updated list after updating a model
      } else {
        console.error('Failed to update model');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const fetchModels = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch('https://pos.farm2bag.com/api/v1/models', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const result = await response.json();
        setModels(result.data); // Store the models from the `data` field
      } else {
        console.error('Failed to fetch models');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };


  const handleDelete = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`https://pos.farm2bag.com/api/v1/models/${modelToDelete.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        toast.success("Model deleted successfully");
        setIsConfirmPopupOpen(false);
        setModelToDelete(null);
        fetchModels(); // Fetch the updated list after deletion
      } else {
        console.error('Failed to delete model');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const showConfirmPopup = (model) => {
    setModelToDelete(model);
    setIsConfirmPopupOpen(true);
  };

  useEffect(() => {
    fetchModels(); // Fetch models when the component mounts
  }, []);

  const filteredModels = models.filter(model =>
    model.pageName.toLowerCase().includes(searchQuery.toLowerCase())
  );


  const totalPages = Math.ceil(filteredModels.length / itemsPerPage); // Calculate total pages

  // Function to handle pagination navigation
  const handlePageChange = (direction) => {
    if (direction === 'prev' && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    } else if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  // Paginated models for the current page
  const paginatedModels = filteredModels.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );


  return (
    <div className='bg-gray-100 ml-64 mt-20 p-12 h-screen'>
      <div className="mb-10">
        <h1 className="font-extrabold text-3xl text-left text-gray-500">Models</h1>
        <div className="mt-2 border-t border-gray-500"></div>
      </div>
      <div className="flex items-center mb-5">
        <div className="ml-0">
          <form>
            <div className="relative">
              <input
                type="text"
                placeholder='Search Models'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500"
              />
              <img src={Searchicon} alt="search" className="absolute left-3 top-3 w-4 h-4" />
            </div>
          </form>
        </div>
        <div className="flex ml-auto mr-0 items-center space-x-4">
          <button
            style={{ backgroundColor: 'rgba(66, 255, 97, 1)' }}
            className="w-48 h-10 font-medium text-sm rounded-lg"
            onClick={togglePopup}
          >
            Create Models
          </button>
        </div>
      </div>

      <div className="rounded-lg overflow-x-auto">
        <table className="table-auto w-full border-collapse border-gray-500 text-center">
          <thead>
            <tr style={{ backgroundColor: "#0307ff" }}>
              <th className="py-2 px-4 border-b-2 border-gray-200 text-white text-left">S.No</th>
              <th className="py-2 px-4 border-b-2 border-gray-200 text-white text-left">Model Name</th>
              <th className="py-2 px-4 border-b-2 border-gray-200 text-white">Status</th>
              <th className="py-2 px-4 border-b-2 border-gray-200 text-white">Created At</th>
              <th className="py-2 px-4 border-b-2 border-gray-200 text-white">Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedModels.map((model, index) => (
              <tr key={model.id} className='hover:bg-green-200'>
                <td className="py-2 px-4 border-gray-200 text-left">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                <td className="py-2 px-4 border-gray-200 text-left text-[#fa7202] font-semibold">{model.pageName}</td>
                <td className="py-2 px-4 border-gray-200 font-semibold" style={{ color: model.status === 'Enable' ? 'green' : 'red' }}>
                  {model.status}
                </td>
                <td className="py-2 px-4 border-gray-200">{new Date(model.createdAt).toLocaleString()}</td>
                <td className="px-4 py-2 border-gray-500 text-center pl-16">
                  <button className="mr-2 font-bold rounded" onClick={() => toggleEditPopup(model)}>
                    <FaRegEdit size={22} style={{ color: "blue" }} className="inline-block mr-1" />
                  </button>

                  <button className=" text-white font-bold rounded" onClick={() => showConfirmPopup(model)}>
                    <IoMdTrash size={30} style={{ color: "red" }} className="inline-block mr-1" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>



      {/* Pagination Controls */}
      <div className="flex items-center justify-between mt-4">
        <button
          onClick={() => handlePageChange('prev')}
          disabled={currentPage === 1}
          className={`px-4 py-2 rounded-lg ${currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'
            }`}
        >
          Previous
        </button>
        <span>
          Page{' '}
          <span className="font-bold text-blue-600">{currentPage}</span>{' '}
          of{' '}
          <span className="font-bold text-green-600">{totalPages}</span>
        </span>

        <button
          onClick={() => handlePageChange('next')}
          disabled={currentPage === totalPages}
          className={`px-4 py-2 rounded-lg ${currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'
            }`}
        >
          Next
        </button>
      </div>


      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80">
          <div className="bg-yellow-500 p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-2xl font-bold mb-2">Create Model</h2>
            <hr />
            <div className="mb-4 mt-2">
              <label className="block text-gray-700 font-medium mb-2">Model Name</label>
              <input
                type="text"
                placeholder='Enter model name'
                value={modelName}
                onChange={(e) => setModelName(e.target.value)}
                className="border border-gray-300 px-4 py-2 rounded-lg w-full focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">Status</label>
              <div className="flex justify-evenly items-center">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 accent-[#00ff00] cursor-grabbing"
                    checked={status === 'Enable'}
                    onChange={() => setStatus('Enable')}
                  />
                  <span className={`ml-2 font-semibold ${status === 'Enable' ? 'text-green-800' : 'text-gray-700'}`}>
                    Enable
                  </span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 accent-[#ed152b] cursor-grabbing"
                    checked={status === 'Disable'}
                    onChange={() => setStatus('Disable')}
                  />
                  <span className={`ml-2 font-semibold ${status === 'Disable' ? 'text-red-600' : 'text-gray-700'}`}>
                    Disable
                  </span>
                </label>
              </div>
            </div>

            <div className="flex justify-between space-x-4">
              <button className="bg-orange-600 text-white px-4 py-2 rounded-lg" onClick={togglePopup}>
                Close
              </button>
              <button className="bg-green-600 text-white px-4 py-2 rounded-lg" onClick={handleCreate}>
                Create
              </button>
            </div>
          </div>
        </div>
      )}

      {isEditPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80">
          <div className="bg-yellow-500 p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-2xl font-bold mb-4">Edit Model</h2>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">Model Name</label>
              <input
                type="text"
                value={modelName}
                onChange={(e) => setModelName(e.target.value)}
                className="border border-gray-300 px-4 py-2 rounded-lg w-full focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">Status</label>
              <div className="flex justify-evenly items-center">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 accent-[#00ff00] cursor-grabbing"
                    checked={status === 'Enable'}
                    onChange={() => setStatus('Enable')}
                  />
                  <span className={`ml-2 font-semibold ${status === 'Enable' ? 'text-green-800' : 'text-gray-700'}`}>
                    Enable
                  </span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 accent-[#ed152b] cursor-grabbing"
                    checked={status === 'Disable'}
                    onChange={() => setStatus('Disable')}
                  />
                  <span className={`ml-2 font-semibold ${status === 'Disable' ? 'text-red-600' : 'text-gray-700'}`}>
                    Disable
                  </span>
                </label>
              </div>
            </div>

            <div className="flex justify-between space-x-4">
              <button className="bg-orange-600 text-white px-4 py-2 rounded-lg" onClick={() => setIsEditPopupOpen(false)}>
                Close
              </button>
              <button className="bg-green-600 text-white px-4 py-2 rounded-lg" onClick={handleUpdate}>
                Update
              </button>
            </div>
          </div>
        </div>
      )}

      {isConfirmPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-orange-400 p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p className="mb-4">Are you sure you want to delete this model <strong className='text-white'>{modelToDelete?.pageName}</strong> ?</p>
            <div className="flex justify-between space-x-4">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-lg"
                onClick={() => setIsConfirmPopupOpen(false)}
              >
                Close
              </button>
              <button
                className="bg-red-600 text-white px-4 py-2 rounded-lg"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Models;
