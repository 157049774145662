import Switch from "react-switch";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import filter from "../Assets/tableicon/filter.png";
import Searchicon from "../Assets/tableicon/Searchicon.png";
import CreateUser from '../components/CreateUser';
import DeleteUser from '../components/DeleteUser';
import EditUser from "../components/EditUser";

const User = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [isSortingActive, setIsSortingActive] = useState(false);
  const [isCreateUserOpen, setIsCreateUserOpen] = useState(false); // Popup state
  const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false); // Delete popup state
  const [userIdToDelete, setUserIdToDelete] = useState(null); // User ID to delete
  const [userNameToDelete, setUserNameToDelete] = useState(""); // User name to delete
  const [isEditUserOpen, setIsEditUserOpen] = useState(false); // Edit popup state
  const [userToEdit, setUserToEdit] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      const response = await fetch("https://pos.farm2bag.com/api/v1/users", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      const usersData = responseData.data.map((user) => ({
        id: user.id,
        userName: user.userName,
        email: user.email,
        userCode: user.userCode,
        roleName: user.roleName,
        status: user.status,
        password: user.password,
        roleId: user.roleId,
        roleName: user.roleName
      }));
      setData(usersData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleStatusChange = async (user) => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found in local storage");
        return;
      }

      const userId = user.id;
      const newStatus = user.status === 0 ? 1 : 0;

      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/users/${userId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            status: newStatus,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update User status");
      }

      setData(
        data.map((item) =>
          item.id === userId ? { ...item, status: newStatus } : item
        )
      );

      toast.success("User status updated successfully");

    } catch (error) {
      console.error("Error updating User status:", error);
      toast.error(error.message || "Error updating User status");
    }
  };
  const handleEdit = (user) => {
    setUserToEdit(user);
    setIsEditUserOpen(true);
  };


  const handleDelete = (userId, userName) => {
    setUserIdToDelete(userId);
    setUserNameToDelete(userName);
    setIsDeleteUserOpen(true);
  };

  const closeCreateUser = () => {
    setIsCreateUserOpen(false);
    fetchData();
  };

  const closeDeleteUser = () => {
    setIsDeleteUserOpen(false);
    fetchData();
  };
  const closeEditUser = () => {
    setIsEditUserOpen(false);
    fetchData();
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleItemsPerPageChange = (e) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(e.target.value));
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSort = () => {
    setIsSortingActive(true);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortedData = [...data];
  if (isSortingActive) {
    sortedData.sort((a, b) => {
      const nameA = a.userName.toUpperCase();
      const nameB = b.userName.toUpperCase();
      if (sortOrder === "asc") {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = sortedData
    .slice(indexOfFirstItem, indexOfLastItem)
    .filter(
      (item) =>
        item.userName &&
        item.userName.toLowerCase().includes(searchQuery.toLowerCase())
    );

  useEffect(() => {
    const totalPages = Math.ceil(data.length / itemsPerPage);
    if (currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [data]);

  return (
    <div className="bg-gray-100 ml-64 mt-20 p-12 h-screen">
      <div className="container mx-auto">
        <div className="mb-10 ">
          <div className="flex ">
            <h1 className="font-extrabold text-3xl text-left text-gray-500">
              User
            </h1>
          </div>
          <div className="mt-2 border-t border-gray-500"></div>
        </div>
        <div className="flex items-center mb-5">
          <div className="ml-0">
            <form>
              <div className="relative">
                <input
                  type="text"
                  placeholder="search"
                  value={searchQuery}
                  onChange={handleSearch}
                  className="border border-gray-200 px-4 py-2 pl-10 rounded-lg focus:outline-none focus:border-blue-500"
                />
                <img
                  src={Searchicon}
                  alt="search"
                  className="absolute left-3 top-3 w-4 h-4"
                />
              </div>
            </form>
          </div>
          <div className="flex ml-auto mr-0 items-center space-x-4">
            <img
              src={filter}
              alt="filter"
              className="w-12 h-10 rounded-lg "
              onClick={handleSort}
            />
            <button
              onClick={() => setIsCreateUserOpen(true)} // Open popup
              className="bg-green-500 text-white px-4 py-2 rounded-lg"
            >
              Create User
            </button>
          </div>
        </div>

        <div className="rounded-lg overflow-x-scroll">
          <table className="table-auto w-full border-collapse border-gray-500 text-center">
            <thead>
              <tr className="bg-blue-500">
                <th className="px-4 pl-16 py-2 text-left text-white">S.No</th> {/* Serial number column */}
                <th className="px-4 py-2 text-left text-white">User Name</th>
                <th className="px-4 py-2 text-left text-white">Email</th>
                <th className="px-4 py-2 text-left text-white">Role</th>
                <th className="px-4 py-2 text-center text-white">Status</th>
                <th className="px-4 py-2 text-center text-white">Actions</th>
              </tr>
            </thead>
            {currentItems.length > 0 ? (
              <tbody>
                {currentItems.map((item, index) => (
                  <tr
                    key={item.id}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-200"}
                  >
                    <td className="px-4 pl-16 py-2 border-gray-500 text-left font-semibold">
                      {index + 1} {/* Display serial number */}
                    </td>
                    <td className="px-4 py-2 border-gray-500 text-left font-semibold">
                      {item.userName}
                    </td>
                    <td className="px-4 py-2 border-gray-500 text-left font-semibold">
                      {item.email}
                    </td>
                    <td className="px-4 py-2 border-gray-500 text-left font-semibold">
                      {item.roleName}
                    </td>
                    <td className="px-4 py-2 border-gray-500 font-semibold">
                      <Switch
                        onChange={() => handleStatusChange(item)}
                        checked={item.status}
                      />
                    </td>
                    <td className="px-4 py-2 border-gray-500 font-semibold">
                      <button
                        onClick={() => handleEdit(item)}
                        className="mr-2 font-bold rounded"
                      >
                        <FaRegEdit size={22} className="inline-block mr-1" />
                      </button>
                      <button
                        onClick={() => handleDelete(item.id, item.userName)}
                        className=" text-white font-bold rounded"
                      >
                        <IoMdTrash
                          size={30}
                          style={{ color: "red" }}
                          className="inline-block mr-1"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="6" className="text-center py-4 font-semibold">
                    No results found.
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>


        <div className="flex justify-between items-center mt-10 pb-10">
          <div>
            <label htmlFor="itemsPerPage">Records per page: </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
          </div>
          <div className="flex justify-center">
            {[...Array(Math.ceil(data.length / itemsPerPage)).keys()].map(
              (number) => (
                <button
                  key={number}
                  onClick={() => paginate(number + 1)}
                  className="mx-1 px-3 py-1 bg-gray-300 hover:bg-gray-400 rounded"
                >
                  {number + 1}
                </button>
              )
            )}
          </div>
        </div>
      </div>
      {isCreateUserOpen && ( // Popup
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <button
            onClick={() => setIsCreateUserOpen(false)} className="absolute top-2 right-2 text-gray-500 hover:text-gray-800">X</button>
          <CreateUser
            onCancel={closeCreateUser}
            onSuccess={closeCreateUser} // Pass closeCreateUser as onSuccess handler
          />
        </div>
      )}

      {isDeleteUserOpen && ( // Delete popup
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <DeleteUser
              userId={userIdToDelete}
              userName={userNameToDelete}
              onCancel={closeDeleteUser}
              onSuccess={closeDeleteUser} // Pass closeDeleteUser as onSuccess handler
            />
          </div>
        </div>
      )}

      {isEditUserOpen && userToEdit && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <EditUser
            user={userToEdit}
            onCancel={closeEditUser}
            onSuccess={closeEditUser}
          />
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default User;
