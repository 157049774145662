import React from 'react';

const BillingActions = async ({ pendingStatus, updatedBillData, updateData, exitsPaymentMode, removedAllProductsTotal, token, updateRemovedProductCount, oldTotalPrice, purchaseProductsCount, overAllReturnTotal }) => {

    try {

        //BELOW METHOD WAS ALREADY "PAID" BUT PAYMENT MODE WAS CHANGE FOR EXAMPLE "CASH" TO "CARD" CONCEPT

        if (pendingStatus === "Paid" && exitsPaymentMode !== updatedBillData.paymentMode && removedAllProductsTotal === 0 && updateRemovedProductCount === 0) {
            const paymentPendingResponse = await fetch(`https://pos.farm2bag.com/api/v1/registers/paymentPending?paymentMode=${exitsPaymentMode}&saleAmount=${updateData.totalPrice}&warehouseId=${updateData.warehouseId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!paymentPendingResponse.ok) {
                throw new Error('Failed to record payment pending status');
            }

            console.log("First Api");

            console.log('Payment pending status recorded successfully');

            const recordSaleResponse = await fetch(`https://pos.farm2bag.com/api/v1/registers/record-sale?paymentMode=${updatedBillData.paymentMode}&saleAmount=${updateData.totalPrice}&warehouseId=${updateData.warehouseId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!recordSaleResponse.ok) {
                throw new Error('Failed to record sale');
            }

            console.log("second Api");

            console.log('Sale recorded successfully');
        } else {
            console.log('No further actions required.');
        }

        //BELOW METHOD WAS "PAID" TO "PENDING" CONCEPT

        if (pendingStatus === "Paid" && updatedBillData.status === "Pending") {
            const paymentPendingResponse = await fetch(`https://pos.farm2bag.com/api/v1/registers/paymentPending?paymentMode=${updateData.paymentMode}&saleAmount=${updateData.totalPrice}&warehouseId=${updateData.warehouseId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!paymentPendingResponse.ok) {
                throw new Error('Failed to record payment pending status');
            }

            console.log("third Api");

            console.log('Payment pending status recorded successfully');
        }

        //BELOW GIVEN CONDITION WAS "PENDING" TO "PAID" CONCEPT

        if (pendingStatus === "Pending" && updatedBillData.status !== "Pending") {
            const recordSaleResponse = await fetch(`https://pos.farm2bag.com/api/v1/registers/record-sale?paymentMode=${updateData.paymentMode}&saleAmount=${updateData.totalPrice}&warehouseId=${updateData.warehouseId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!recordSaleResponse.ok) {
                throw new Error('Failed to record sale');
            }

            console.log("fouth Api");

            console.log('Sale recorded successfully');
        }

        //BELOW GIVEN CONDITION WAS "COD" TO "PAID" CONCEPT

        if (pendingStatus === "COD" && updatedBillData.status !== "Pending" && updatedBillData.status !== "COD" && updatedBillData.status === "Paid") {
            const recordSaleResponse = await fetch(`https://pos.farm2bag.com/api/v1/registers/record-sale?paymentMode=${updatedBillData.paymentMode}&saleAmount=${updatedBillData.updateTotalPrice}&warehouseId=${updateData.warehouseId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!recordSaleResponse.ok) {
                throw new Error('Failed to record sale');
            }

            console.log("fiveth Api");

            console.log('Sale recorded successfully');
        }



        //BELOW CONDITION WAS NEW RETURN PRODUCTS WITH PAYMENT MODE CHANGE CONCEPT

        if (pendingStatus === "Paid" && exitsPaymentMode !== updatedBillData.paymentMode && updateRemovedProductCount > 0) {
            const paymentPendingResponse = await fetch(`https://pos.farm2bag.com/api/v1/registers/paymentPending?paymentMode=${exitsPaymentMode}&saleAmount=${oldTotalPrice}&warehouseId=${updateData.warehouseId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!paymentPendingResponse.ok) {
                throw new Error('Failed to record payment pending status');
            }

            console.log("sixth Api");

            console.log('Payment pending status recorded successfully');

            const recordSaleResponse = await fetch(`https://pos.farm2bag.com/api/v1/registers/record-sale?paymentMode=${updatedBillData.paymentMode}&saleAmount=${updateData.totalPrice}&warehouseId=${updateData.warehouseId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!recordSaleResponse.ok) {
                throw new Error('Failed to record sale');
            }

            console.log("sevanth Api");

            console.log('Sale recorded successfully');
        } else {
            console.log('No further actions required.');
        }

        //BELOW GIVEN METHOD WAS ALREADY "PAID" AND ONE OR TWO VALUES "REMOVED" ,NOW WITHOUT CHANGE IN PAYMENT MODE , ONLY NEW "REMOVED" THE PRODUCTS CONCEPT

        // if (pendingStatus === "Paid" && updateRemovedProductCount > 0) {
        //     const paymentPendingResponse = await fetch(`https://pos.farm2bag.com/api/v1/registers/paymentPending?paymentMode=${updateData.paymentMode}&saleAmount=${removedAllProductsTotal}&warehouseId=${updateData.warehouseId}`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': `Bearer ${token}`
        //         }
        //     });

        //     if (!paymentPendingResponse.ok) {
        //         throw new Error('Failed to record payment pending status for removed products');
        //     }

        //console.log("eight Api");


        //     console.log('Payment pending status for removed products recorded successfully');
        // }

    } catch (error) {
        console.error('Error in BillingActions:', error);
    }
};

export default BillingActions;