import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import emailbox from "../Assets/login/emaillogo.png";
import passlock from "../Assets/login/passlock.png";
import fruitsTruck from "../Assets/login/fruits-truck.png";
import f2bImage from "../Assets/login/f2bimage.png";
import '../scss/Login.scss';
import LoginLogsSave from '../components/LoginLogsSave';


function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const navigate = useNavigate();

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(
      emailRegex.test(value) ? "" : "Please enter a valid email address"
    );
  };

  const validatePassword = (value) => {
    setPasswordError(
      value.length >= 8 ? "" : "Password must be at least 8 characters long"
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (emailError || passwordError) {
      return;
    }

    try {
      const response = await fetch("https://pos.farm2bag.com/api/v1/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();

        const { token, role, user, modelNames, statusText } = data;

        if (statusText === "Login Successful") {
          localStorage.setItem("token", token);
          localStorage.setItem("role", role);
          localStorage.setItem("userName", user.userName);
          localStorage.setItem("modelNames", modelNames);

          // setLoginData({ token, role, user});
          await LoginLogsSave({ token, role, user });

          setShowSuccessMessage(true);
          // Only call saveStockLog when login is successful
          await saveStockLog(token);

          setTimeout(() => {
            navigate("/Dashboard");
          }, 2000);
        } else {
          toast.error("Login failed! Status is not 'Login Successful'.");
        }
      } else {
        const data = await response.json(); // Get the response body when login fails
        const errorMessage = data.statusText || "Login failed!"; // Extract the error message
        toast.error(`Login failed! ${errorMessage}`);
      }
    } catch (error) {
      console.error(`Error occurred while logging in: ${error.message || error}`);
    }
  };

  const saveStockLog = async (token) => {
    try {
      const status = "Login Successfully";

      const url = new URL("https://pos.farm2bag.com/api/v1/stocksLogs/save-log");
      url.searchParams.append("status", status);

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });

      if (response.ok) {
        console.log("Stock log saved successfully");
      } else {
        const errorMessage = await response.text();
        toast.error(`Failed to save stock log: ${errorMessage}`);
      }
    } catch (error) {
      console.error(`Error occurred while saving stock log: ${error.message || error}`);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (showSuccessMessage) {
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage]);

  return (
    <div className="login-container">
      <div className="login-box">
        <img src={f2bImage} alt="" className="w-20 h-20 mx-auto" />
        <h1 className="store-title">farm<span style={{ color: "#fa9d07" }}>2</span>bag</h1>
        <p className="subtitle">Organic Store - Fresh & Pure</p>

        <form onSubmit={handleSubmit} className="login-form">
          <div className="input-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                const lowercasedValue = e.target.value.toLowerCase();
                setEmail(lowercasedValue);
                validateEmail(lowercasedValue);
              }}
              required
              className="input-field"
            />
            <img src={emailbox} alt="Email" className="input-icon" />
            {emailError && <p className="error-text">{emailError}</p>}
          </div>

          <div className="input-group">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                validatePassword(e.target.value);
              }}
              required
              className="input-field"
            />
            <img src={passlock} alt="Password" className="input-icon" />
            {passwordError && <p className="error-text">{passwordError}</p>}
          </div>

          <button type="submit" className="login-button">Login</button>
        </form>

      </div>
      {showSuccessMessage && (
        <div className="success-message">
          <div className="success-content">
            <div className="success-icon">
              <svg
                width="50px"
                height="50px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="#fa7107"
                  strokeWidth="2"
                  fill="none"
                />
                <path
                  d="M6 12l4 4l8-8"
                  stroke="#28fa07"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="checkmark"
                />
              </svg>
            </div>
            <p className="success-text">Logged In successfully</p>

            <img
              src={fruitsTruck}
              alt="Login Success"
              className="success-image"
            />
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default Login;