import React from 'react';

const LoginLogsSave = async ({ token, role, user }) => {
  // Prepare the login log data with only the required fields
  const loginLogData = {
    email: user.email,
    userCode: user.userCode,
    roleId: user.roleId,
    role: role,
    userName: user.userName,
    userId: user.id,
  };

  try {
    const response = await fetch('https://pos.farm2bag.com/api/v1/loginLogs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Include the token for authorization
      },
      body: JSON.stringify(loginLogData),
    });

    if (response.ok) {
      const responseData = await response.json(); // Parse the JSON response
      console.log('Login log saved successfully');

      // Save the id value to localStorage
      if (responseData && responseData.data && responseData.data.id) {
        localStorage.setItem('LoginLogsUserId', responseData.data.id);
        console.log('LoginLogsUserId saved to localStorage:', responseData.data.id);
      } else {
        console.error('Response data does not contain id');
      }
    } else {
      const errorMessage = await response.text();
      console.error('Failed to save login log:', errorMessage);
    }
  } catch (error) {
    console.error('Error occurred while saving login log:', error.message || error);
  }
};

export default LoginLogsSave;
