import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';


const StockReport = () => {
  const [warehouses, setWarehouses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [stockLogs, setStockLogs] = useState([]);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Pagination: Current page
  const itemsPerPage = 10; // Pagination: Items per page
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const warehouseResponse = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const warehouseData = await warehouseResponse.json();
        if (warehouseData.status === 200) {
          setWarehouses(warehouseData.data);
          if (warehouseData.data.length > 0) {
            setSelectedWarehouseId(warehouseData.data[0].id);
          }
        } else {
          console.error('Failed to fetch warehouses:', warehouseData.message);
        }

        const categoryResponse = await fetch('https://pos.farm2bag.com/api/v1/categories/getAll/master', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const categoryData = await categoryResponse.json();
        if (categoryData.status === 200) {
          setCategories(categoryData.data);
        } else {
          console.error('Failed to fetch categories:', categoryData.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    const fetchStockLogs = async () => {
      try {
        const url = new URL('https://pos.farm2bag.com/api/v1/stocksLogs');

        if (selectedWarehouseId) url.searchParams.append('warehouseId', selectedWarehouseId);
        if (selectedCategory) url.searchParams.append('productCategoryId', selectedCategory);
        if (fromDate) url.searchParams.append('fromDate', dayjs(fromDate).format('YYYY-MM-DD'));
        if (toDate) url.searchParams.append('toDate', dayjs(toDate).format('YYYY-MM-DD'));

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        if (response.ok) {
          setStockLogs(data.data);
          setCurrentPage(1); // Reset to first page when filters change
        } else {
          console.error('Failed to fetch stock logs:', data.message);
        }
      } catch (error) {
        console.error('Error fetching stock logs:', error);
      }
    };

    fetchStockLogs();
  }, [selectedWarehouseId, selectedCategory, fromDate, toDate, token]);

  const totalPages = Math.ceil(stockLogs.length / itemsPerPage);
  const currentLogs = stockLogs.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (direction) => {
    if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    } else if (direction === 'prev' && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };


  const downloadExcel = () => {
    const formattedLogs = stockLogs.map((log) => ({
      'Product Code': log.productCode,
      'Product Name': log.productName,
      Quantity: log.quantity.toFixed(2),
      Price: log.price.toFixed(2),
      'Manufacturing Date': log.mafDate,
      'Expiry Date': log.expDate,
      Track: log.track,
    }));

    const ws = XLSX.utils.json_to_sheet(formattedLogs);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Stock Logs');

    // Download the Excel file
    XLSX.writeFile(wb, 'stock_logs.xlsx');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (

    <div className="px-2 py-0 bg-white text-black min-h-screen">
      <h2 className="font-extrabold text-4xl text-left text-teal-600">Stock Logs</h2>
      <div className="flex flex-wrap items-center space-x-6 p-4 bg-white text-black">
        <select
          onChange={(e) => setSelectedWarehouseId(e.target.value)}
          value={selectedWarehouseId}
          className="w-44 p-2 bg-gray-100 text-black border border-gray-300 rounded focus:ring focus:ring-blue-500 focus:outline-none"
        >
          <option value="">All Warehouses</option>
          {warehouses.map((warehouse) => (
            <option key={warehouse.id} value={warehouse.id}>
              {warehouse.name}
            </option>
          ))}
        </select>

        <select
          onChange={(e) => setSelectedCategory(e.target.value)}
          value={selectedCategory}
          className="w-44 p-2 bg-gray-100 text-black border border-gray-300 rounded focus:ring focus:ring-blue-500 focus:outline-none"
        >
          <option value="">All Categories</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.categoryName}
            </option>
          ))}
        </select>


        <div className="flex space-x-4">
          <div className="flex items-center space-x-2">
            <label className="text-black font-medium">From Date</label>
            <DatePicker
              value={fromDate}
              placeholder="From Date"
              onChange={(date) => setFromDate(date)}
              className="date-picker-style p-2 bg-gray-100 text-black border border-gray-300 rounded focus:ring focus:ring-blue-500 focus:outline-none"
            />
          </div>

          <div className="flex items-center space-x-2">
            <label className="text-black font-medium">To Date</label>
            <DatePicker
              value={toDate}
              placeholder="To Date"
              onChange={(date) => setToDate(date)}
              className="date-picker-style p-2 bg-gray-100 text-black border border-gray-300 rounded focus:ring focus:ring-blue-500 focus:outline-none"
            />
          </div>
        </div>

        <button onClick={downloadExcel} className=" px-4 py-2 bg-green-500 text-white rounded"> Download Excel</button>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full text-left border-collapse border border-gray-300 shadow-md">
          <thead className="bg-gradient-to-r from-blue-600 to-blue-400 text-white">
            <tr>
              <th className="py-3 px-5 border-b border-gray-300 font-semibold tracking-wide text-nowrap">Product Code</th>
              <th className="py-3 px-5 border-b border-gray-300 font-semibold tracking-wide text-nowrap">Product Name</th>
              <th className="py-3 px-5 border-b border-gray-300 font-semibold tracking-wide text-nowrap">Quantity</th>
              <th className="py-3 px-5 border-b border-gray-300 font-semibold tracking-wide text-nowrap">Price</th>
              <th className="py-3 px-5 border-b border-gray-300 font-semibold tracking-wide text-nowrap">Manufacturing Date</th>
              <th className="py-3 px-5 border-b border-gray-300 font-semibold tracking-wide text-nowrap">Expiry Date</th>
              <th className="py-3 px-5 border-b border-gray-300 font-semibold tracking-wide text-nowrap">Track</th>
            </tr>
          </thead>
          <tbody>
            {currentLogs.length > 0 ? (
              currentLogs.map((log, index) => (
                <tr
                  key={index}
                  className={`hover:bg-gray-100 transition-colors duration-200 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
                >
                  <td className="py-3 px-5 border-b border-gray-300 font-semibold text-red-600 text-nowrap">
                    {log.productCode}
                  </td>
                  <td className="py-3 px-5 border-b border-gray-300 font-bold text-gray-800 text-nowrap">
                    {log.productName}
                  </td>
                  <td className="py-3 px-5 border-b border-gray-300 text-gray-700 text-nowrap">
                    {log.quantity.toFixed(2)}
                  </td>
                  <td className="py-3 px-5 border-b border-gray-300 text-gray-700 text-nowrap">
                    {log.price.toFixed(2)}
                  </td>

                  <td className="py-3 px-5 border-b border-gray-300 text-gray-700 text-nowrap">
                    {log.mafDate}
                  </td>
                  <td className="py-3 px-5 border-b border-gray-300 text-gray-700 text-nowrap">
                    {log.expDate}
                  </td>
                  <td className="py-3 px-5 border-b border-gray-300 text-gray-700 text-nowrap">
                    {log.track}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="py-6 px-5 text-center text-gray-500 italic">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => handlePageChange('prev')}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          Previous
        </button>
        <div className="text-sm font-medium text-gray-900">
          Page <span className="text-blue-600">{currentPage}</span> of <span className="text-blue-600">{totalPages}</span>
        </div>
        <button
          onClick={() => handlePageChange('next')}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </div>
    </div>

  );
};

export default StockReport;
