import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateUser = ({ onCancel, onSuccess }) => {
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(null);
  const [userName, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formErrors, setFormErrors] = useState({
    userName: '',
    email: '',
    password: '',
    role: '',
  });

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          console.error("No token found in local storage");
          setError("No token found in local storage");
          return;
        }

        const response = await fetch('https://pos.farm2bag.com/api/v1/roles', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setRoles(data.data);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        setError(error.message);
      }
    };

    fetchRoles();
  }, []);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name === 'role') {
      setSelectedRole(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    const formValid =
      userName &&
      email &&
      selectedRole &&
      password.length >= 8;

    if (!formValid) {
      setFormErrors({
        userName: userName ? '' : 'Username is required',
        email: email ? '' : 'Email is required',
        password: password ? (password.length < 8 ? 'Password must be at least 8 characters long' : '') : 'Password is required',
        role: selectedRole ? '' : 'Role selection is required',
      });
      return;
    }

    try {
      const token = localStorage.getItem("token");

      const response = await fetch('https://pos.farm2bag.com/api/v1/users', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userName,
          email,
          password,
          roleId: selectedRole
        }),
      });

      if (!response.ok) {
        if (response.status === 409) { // Assuming 409 is the status code for duplicate email
          throw new Error('A user with the same email already exists');
        }
        throw new Error('Network response was not ok');
      }

      // Clear form and show success message
      setUsername('');
      setEmail('');
      setPassword('');
      setSelectedRole('');
      setFormErrors({ userName: '', email: '', password: '', role: '' });

      onSuccess(); // Close the popup
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      if (error.message === 'A user with the same email already exists') {
        toast.error('A user with the same email already exists');
      } else {
        setError(error.message);
      }
    }
  };

  return (

    <div className="bg-gray-50 p-8 rounded-lg shadow-md max-w-lg mx-auto">
      <h2 className="text-2xl font-bold mb-6 text-center">Create New User</h2>
      <form onSubmit={handleSubmit}>

        <div className="mb-6 flex items-center">
          <label htmlFor="username" className="w-1/3 text-gray-700 text-sm font-medium mr-4">User Name</label>
          <div className="w-2/3">
            <input
              type="text"
              placeholder='User Name'
              name="username"
              id="username"
              value={userName}
              onChange={handleInputChange}
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-green-500 transition-colors duration-300 ease-in-out ${formErrors.userName ? 'border-red-500' : 'border-gray-300'
                }`}
            />
            {formErrors.userName && <p className="text-red-500 mt-1 text-sm">{formErrors.userName}</p>}
          </div>
        </div>

        <div className="mb-6 flex items-center">
          <label htmlFor="email" className="w-1/3 text-gray-700 text-sm font-medium mr-4">Email</label>
          <div className="w-2/3">
            <input
              placeholder='Email'
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={handleInputChange}
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-green-500 transition-colors duration-300 ease-in-out ${formErrors.email ? 'border-red-500' : 'border-gray-300'
                }`}
            />
            {formErrors.email && <p className="text-red-500 mt-1 text-sm">{formErrors.email}</p>}
          </div>
        </div>

        <div className="mb-6 flex items-center">
          <label htmlFor="password" className="w-1/3 text-gray-700 text-sm font-medium mr-4">Password</label>
          <div className="w-2/3">
            <input
              placeholder='Password'
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={handleInputChange}
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-green-500 transition-colors duration-300 ease-in-out ${formErrors.password ? 'border-red-500' : 'border-gray-300'
                }`}
            />
            {formErrors.password && <p className="text-red-500 mt-1 text-sm">{formErrors.password}</p>}
          </div>
        </div>

        <div className="mb-6 flex items-center">
          <label htmlFor="role" className="w-1/3 text-gray-700 text-sm font-medium mr-4">Role</label>
          <div className="w-2/3">
            <select
              name="role"
              id="role"
              value={selectedRole}
              onChange={handleInputChange}
              className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-green-500 transition-colors duration-300 ease-in-out ${formErrors.role ? 'border-red-500' : 'border-gray-300'
                }`}
            >
              <option value="">Select Role</option>
              {roles.map(role => (
                <option key={role.roleId} value={role.roleId}>
                  {role.roleName}
                </option>
              ))}
            </select>
            {formErrors.role && <p className="text-red-500 mt-1 text-sm">{formErrors.role}</p>}
          </div>
        </div>

        <div className="flex justify-between mt-6">
          <button
            type="button"
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
            >
            Create User
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default CreateUser;
