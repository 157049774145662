import React, { useState, useEffect } from 'react';
import Eye from "../Assets/tableicon/Eye.svg";
import { format } from 'date-fns';
import * as XLSX from 'xlsx';


const SalesReturn = () => {
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState('all');
  const [dateRange, setDateRange] = useState('all');
  const [customDates, setCustomDates] = useState({ from: '', to: '' });
  const [salesReturnData, setSalesReturnData] = useState([]);
  const [filteredSalesReturns, setFilteredSalesReturns] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReturnItem, setSelectedReturnItem] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    const token = localStorage.getItem('token');

    const fetchWarehouseData = async () => {
      try {
        const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        if (data && Array.isArray(data.data)) {
          setWarehouses(data.data);
        }
      } catch (error) {
        console.error('Error fetching warehouses:', error);
      }
    };

    const fetchSalesReturnData = async () => {
      try {
        const response = await fetch('https://pos.farm2bag.com/api/v1/billing/getAll-return', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        if (data && Array.isArray(data.data)) {
          setSalesReturnData(data.data);
        }
      } catch (error) {
        console.error('Error fetching sales return data:', error);
      }
    };

    fetchWarehouseData();
    fetchSalesReturnData();
  }, []);

  const handleWarehouseChange = (e) => {
    setSelectedWarehouse(e.target.value);
  };

  const handleDateRangeChange = (e) => {
    setDateRange(e.target.value);
  };

  useEffect(() => {
    let filteredData = salesReturnData;

    if (selectedWarehouse && selectedWarehouse !== 'all') {
      filteredData = filteredData.filter((item) => item.warehouseId === selectedWarehouse);
    }

    if (dateRange === 'today') {
      const today = new Date();
      filteredData = filteredData.filter((item) =>
        new Date(item.billing.modifiedAt).toDateString() === today.toDateString()
      );
    } else if (dateRange === 'thisWeek') {
      const startOfWeek = new Date();
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(endOfWeek.getDate() + 6);
      filteredData = filteredData.filter((item) =>
        new Date(item.billing.modifiedAt) >= startOfWeek && new Date(item.billing.modifiedAt) <= endOfWeek
      );
    } else if (dateRange === 'thisMonth') {
      const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      const endOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
      filteredData = filteredData.filter((item) =>
        new Date(item.billing.modifiedAt) >= startOfMonth && new Date(item.billing.modifiedAt) <= endOfMonth
      );
    } else if (dateRange === 'lastMonth') {
      const startOfLastMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
      const endOfLastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
      filteredData = filteredData.filter((item) =>
        new Date(item.billing.modifiedAt) >= startOfLastMonth && new Date(item.billing.modifiedAt) <= endOfLastMonth
      );
    } else if (dateRange === 'custom' && customDates.from && customDates.to) {
      const fromDate = new Date(customDates.from);
      const toDate = new Date(customDates.to);
      filteredData = filteredData.filter((item) => {
        const itemDate = new Date(item.billing.modifiedAt);
        return itemDate >= fromDate && itemDate <= toDate;
      });
    }

    setFilteredSalesReturns(filteredData);
  }, [selectedWarehouse, dateRange, salesReturnData, customDates]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const getProductCount = (billingItems) => {
    return billingItems?.reduce((total, item) => total + (Array.isArray(item?.product) ? item.product.length : 0), 0) || 0;
  };



  const paginateData = (data) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const openModal = (item) => {
    setSelectedReturnItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedReturnItem(null);
  };


  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredSalesReturns.map(item => ({
      Invoice: item.billing.invoice,
      Customer: item.customer.name,
      Warehouse: item.warehouseName,
      ProductCount: getProductCount(item.billingItems) + ' Products',
      Date: format(new Date(item.billingItems[0].createdAt), 'yyyy-MM-dd')
    })));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sales Returns');
    XLSX.writeFile(wb, 'SalesReturns.xlsx');
  };

  const totalPages = Math.ceil(filteredSalesReturns.length / itemsPerPage);

  return (
    <div className="p-4">
      <h2 className="font-extrabold text-4xl text-left text-teal-600">Sales Return</h2>

      <div className="flex gap-4 mb-4">
        <select value={selectedWarehouse} onChange={handleWarehouseChange} className="p-2 border rounded">
          <option value="all">All Warehouses</option>
          {warehouses.map((warehouse) => (
            <option key={warehouse.id} value={warehouse.id}>
              {warehouse.name}
            </option>
          ))}
        </select>

        <select value={dateRange} onChange={handleDateRangeChange} className="p-2 border rounded">
          <option value="all">All Dates</option>
          <option value="today">Today</option>
          <option value="thisWeek">This Week</option>
          <option value="thisMonth">This Month</option>
          <option value="lastMonth">Last Month</option>
          <option value="custom">Custom</option>
        </select>

        <button
          onClick={downloadExcel}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Download Excel
        </button>

        {dateRange === 'custom' && (
          <div className="flex gap-2">
            <input
              type="date"
              value={customDates.from}
              onChange={(e) => setCustomDates({ ...customDates, from: e.target.value })}
              className="p-2 border rounded"
            />
            <input
              type="date"
              value={customDates.to}
              onChange={(e) => setCustomDates({ ...customDates, to: e.target.value })}
              className="p-2 border rounded"
            />
          </div>
        )}
      </div>

      <table className="w-full mt-4 border-collapse border">
        <thead className="bg-blue-500">
          <tr>
            <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">Invoice</th>
            <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">Customer</th>
            <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">Warehouse</th>
            <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">Product Count</th>
            <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">Date</th>
            <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">Action</th>
          </tr>
        </thead>
        <tbody>
          {paginateData(filteredSalesReturns).length === 0 ? (
            <tr>
              <td colSpan="6" className="text-center p-4">
                No sales returns found
              </td>
            </tr>
          ) : (
            paginateData(filteredSalesReturns).map((returnItem) => (
              <tr key={returnItem.billing.id}>
                <td className="py-3 px-5 border-gray-300 font-semibold text-red-600 text-nowrap">{returnItem.billing.invoice}</td>
                <td className="text-center p-2">{returnItem.customer.name}</td>
                <td className="text-center p-2">{returnItem.warehouseName}</td>
                <td className="p-2">{getProductCount(returnItem.billingItems)} Products</td>

                <td className="text-center p-2">
                  {format(new Date(returnItem.billingItems[0].createdAt), 'yyyy-MM-dd')}
                </td>



                <td className="text-center p-2">
                  <button
                    onClick={() => openModal(returnItem)}
                    className="text-white px-4 py-2 rounded"
                  >
                    <img src={Eye} alt="View" className="inline-block w-4 h-4" />
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <div className="flex justify-center items-center gap-4 mt-6">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`p-2 px-4 rounded-lg text-white ${currentPage === 1 ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
        >
          Prev
        </button>

        <span className="text-sm font-medium text-gray-700">
          Page {currentPage} of {totalPages}
        </span>

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`p-2 px-4 rounded-lg text-white ${currentPage === totalPages ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
        >
          Next
        </button>
      </div>


      {isModalOpen && selectedReturnItem && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg w-3/4 md:w-1/2">
            <h3 className="text-xl font-bold mb-4">Sales Return Details</h3>
            <div className="mt-4">
              <div className="flex justify-between mb-2">
                <strong>Invoice:</strong>
                <span>{selectedReturnItem.billing?.id || 'N/A'}</span>
              </div>

              <div className="flex justify-between mb-2">
                <strong>Customer:</strong>
                <span>{selectedReturnItem.customer?.name || 'N/A'}</span>
              </div>

              <div className="flex justify-between mb-2">
                <strong>Warehouse:</strong>
                <span>{selectedReturnItem.warehouseName || 'N/A'}</span>
              </div>

              <div className="flex justify-between mb-4">
                <strong>Product Count:</strong>
                <span>
                  {selectedReturnItem.billingItems?.reduce(
                    (total, item) => total + item.product.length,
                    0
                  ) || 0}
                </span>
              </div>

              <div className="mt-4">
                <strong className="block text-lg mb-2">Product Details:</strong>
                <table className="w-full border-collapse border border-gray-300">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="border border-gray-300 px-4 py-2">#</th>
                      <th className="border border-gray-300 px-4 py-2">Name</th>
                      <th className="border border-gray-300 px-4 py-2">Price</th>
                      <th className="border border-gray-300 px-4 py-2">Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedReturnItem.billingItems?.map((item, itemIndex) =>
                      item.product?.map((product, productIndex) => (
                        <tr key={`${itemIndex}-${productIndex}`} className="hover:bg-gray-50">
                          <td className="border border-gray-300 px-4 py-2 text-center">
                            {itemIndex + 1}.{productIndex + 1}
                          </td>
                          <td className="border border-gray-300 px-4 py-2">
                            {product.productName || 'N/A'}
                          </td>
                          <td className="border border-gray-300 px-4 py-2 text-center">
                            ₹{product.totalPrice || '0.00'}
                          </td>
                          <td className="border border-gray-300 px-4 py-2 text-center">
                            {product.quantity || '0'}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Close Button */}
            <div className="mt-4 flex justify-end">
              <button
                onClick={closeModal}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default SalesReturn;
