import React from "react";

const NotsPopup = ({ isOpen, notsContent, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
      <div className="bg-white w-full max-w-md rounded-lg shadow-lg p-4">
        <div className="flex justify-between items-center mb-3">
          <h2 className="text-lg font-semibold text-gray-800">Invoice Notes</h2>
        </div>
        
        <div className="bg-gray-100 p-4 rounded-lg shadow-sm">
          <p className="text-gray-800 text-sm">{notsContent || "No notes available."}</p>
        </div>

        <div className="mt-4 flex justify-end">
          <button
            onClick={onClose}
            className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-full text-sm font-medium"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotsPopup;