import React from 'react';
import { useSpring, animated } from '@react-spring/web';

const AnimatedNumber = ({ value, formatter }) => {
    const { number } = useSpring({
        from: { number: 0 },
        to: { number: value },
        config: { duration: 3000 },
    });

    return <animated.span>{number.to((n) => formatter(n))}</animated.span>;
};

export default AnimatedNumber;
