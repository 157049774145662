import React, { useState, useEffect } from 'react';
import RegisterTotals from '../components/RegisterTotals';
import RegisterTable from '../components/RegisterTable';
import AnimatedNumber from '../components/AnimatedNumber';

const Register = () => {
    const [registers, setRegisters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRegisters = async () => {
            try {
                const token = localStorage.getItem('token');

                const response = await fetch('https://pos.farm2bag.com/api/v1/registers/all', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                const data = await response.json();

                if (response.ok) {
                    setRegisters(data.data);
                } else {
                    setError(data.message);
                }
            } catch (err) {
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchRegisters();
    }, []);


    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }


    return (
        <div className="ml-64 mt-20 p-12 h-screen">
            <div className="container mx-auto pb-10">
                <div className="mb-10">
                    <h1 className="font-extrabold text-4xl text-left text-teal-600">LEDGER</h1>
                    <div className="mt-2 border-t border-teal-600"></div>
                </div>

                <div className="mb-10">
                    <RegisterTotals />
                </div>

                <div className="grid grid-cols-1 gap-6">
                    {registers.map((register) => (
                        <div
                            key={register.id}
                            className="relative p-6 h-80 bg-white shadow-lg rounded-lg grid grid-rows-4 grid-cols-4 gap-4 transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-2xl bg-gradient-to-r from-teal-100 via-blue-100 to-purple-100"
                        >
                            <div className="absolute top-0 left-0 p-4 text-left">
                                <h2 className="font-semibold text-5xl text-teal-800">{register.warehouseName}</h2>
                                <p
                                    className={`text-gray-700 w-20 text-center rounded-lg text-lg ${register.status === 'closed'
                                            ? 'text-white bg-red-500'
                                            : register.status === 'open'
                                                ? 'text-white bg-green-500'
                                                : ''
                                        } font-bold`}
                                >
                                    {register.status}
                                </p>
                            </div>
                            <div className="absolute left-0 top-1/2 transform -translate-y-1/2 p-4 mt-8 text-left">
                                <p className="text-blue-600 pt-5 text-xl font-bold">
                                    Open Date/Time:{' '}
                                    <span className="text-green-600">
                                        {new Date(register.startDate).toLocaleString()}
                                    </span>
                                </p>
                                <p className="text-blue-600 pt-5 text-xl font-bold">
                                    Close Date/Time:{' '}
                                    <span className="text-green-600">
                                        {register.endDate ? new Date(register.endDate).toLocaleString() : 'N/A'}
                                    </span>
                                </p>
                                <div>
                                    <p className="text-pink-500 pt-5 text-xl font-bold">
                                        Total Capital Amount:{' '}
                                        <span className="text-green-600">
                                            <AnimatedNumber
                                                value={register.totalCapitalAmount}
                                                formatter={formatCurrency}
                                            />{' '}
                                            <span className="text-sm text-orange-500">(excluded)</span>
                                        </span>
                                    </p>
                                    <p className="text-pink-500 pt-5 text-xl font-bold">
                                        Total Expense Amount:{' '}
                                        <span className="text-green-600">
                                            <AnimatedNumber
                                                value={register.totalExpenseAmount}
                                                formatter={formatCurrency}
                                            />{' '}
                                            <span className="text-sm text-orange-500">(excluded)</span>
                                        </span>
                                    </p>
                                </div>
                            </div>

                            <div className="col-span-4 mt-4 rounded-lg p-4 flex flex-col items-center">
                                <div className="grid grid-cols-2 gap-5 w-80">
                                    {register.billingData.map((data, index) => (
                                        <div
                                            key={index}
                                            className="flex flex-col items-center w-full ml-5 transition-transform transform hover:scale-110 duration-200"
                                        >
                                            <h4 className="text-xl font-semibold text-blue-600 mb-4 underline capitalize">
                                                {data.orderType}
                                            </h4>
                                            <table className="w-full text-left border-collapse border border-blue-200 rounded-lg shadow-sm">
                                                <thead className="bg-blue-200">
                                                    <tr>
                                                        <th className="p-2 border border-blue-200 text-blue-700 font-bold">Type</th>
                                                        <th className="p-2 border border-blue-200 text-blue-700 font-bold"> Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="p-2 border border-blue-200 text-green-700 font-bold">Digital</td>
                                                        <td className="p-2 border border-blue-200 text-red-500 font-extrabold">
                                                            <AnimatedNumber value={data.digital} formatter={formatCurrency} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2 border border-blue-200 text-green-700 font-bold"> Cash</td>
                                                        <td className="p-2 border border-blue-200 text-red-500 font-extrabold">
                                                            <AnimatedNumber value={data.cash} formatter={formatCurrency}/>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2 border border-blue-200 text-green-700 font-bold"> Card</td>
                                                        <td className="p-2 border border-blue-200 text-red-500 font-extrabold">
                                                            <AnimatedNumber value={data.card} formatter={formatCurrency}/>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="absolute top-0 right-0 p-4 text-right pr-10">
                                <p className="text-yellow-600 text-xl font-bold">
                                    City: <span className="text-green-600">{register.city || 'N/A'}</span>
                                </p>
                            </div>
                            <div className="absolute right-0 top-1/2 transform -translate-y-1/2 pr-10 text-right">
                                <p className="text-yellow-600 text-xl font-bold">
                                    In-Hand Cash:{' '}
                                    <span className="text-green-600">
                                        <AnimatedNumber value={register.inHandCash} formatter={formatCurrency}/>
                                    </span>
                                </p>
                                <p className="text-yellow-600 text-xl pt-5 font-bold">
                                    Card Received:{' '}
                                    <span className="text-green-600">
                                        <AnimatedNumber value={register.cardReceived} formatter={formatCurrency}/>
                                    </span>
                                </p>
                                <p className="text-yellow-600 pt-5 text-xl font-bold">
                                    UPI Received:{' '}
                                    <span className="text-green-600">
                                        <AnimatedNumber value={register.digitalReceived} formatter={formatCurrency}/>
                                    </span>
                                </p>
                                <p className="text-yellow-600 pt-5 text-xl font-bold">
                                    Cash Received:{' '}
                                    <span className="text-green-600">
                                        <AnimatedNumber value={register.cashReceived} formatter={formatCurrency}/>
                                    </span>
                                </p>
                            </div>
                            <div className="absolute bottom-0 right-0 pb-4 text-right pr-10 bg-gradient-to-r from-orange-400 via-yellow-100 to-green-300 pt-2 rounded-lg">
                                <p className="text-red-600 text-xl font-bold pl-5">
                                    Total Funds:{' '}
                                    <span className="text-green-600">
                                        <AnimatedNumber value={register.totalSalesAmount} formatter={formatCurrency}/>
                                    </span>
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
                <RegisterTable registers={registers} />
            </div>
        </div>
    );
};

export default Register;