import React, { useRef, useState, useEffect } from 'react';
import Modal from 'react-modal';
import f2b_logo from "../Assets/Billingpage/invoice-logo-f2b.jpg";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadPdfToS3 } from './Aws'; // Import the uploadPdfToS3 function
import f2bLink from "../Assets/Billingpage/f2bLink.png";
import Gift from "../Assets/Billingpage/gift.png";


const InvoicePopup = ({ isOpen, onClose, invoiceData }) => {
    const invoiceRef = useRef(null);
    const [uploading, setUploading] = useState(false);

    if (!invoiceData) return null;

    const { warehouseName, customerName, contactNumber, totalPrice, productDetails, overallSubTotal, deliveryCharge, discount, paymentMode, responseData, gst, gstAmount, selectedGiftPrice } = invoiceData;

    const discounts = discount || 0;
    const deliveryCharges = deliveryCharge || 0;

    const discountAmount = (overallSubTotal * discounts) / 100; // Calculate the actual discount value
    const totalAmount = overallSubTotal + gstAmount + deliveryCharges - discountAmount; // Calculate the final total

    const currencyFormatter = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR'
    });


    const handleDownloadPDF = () => {
        if (!invoiceRef.current) return;

        const invoiceElement = invoiceRef.current;
        hideButtons(invoiceElement);

        html2canvas(invoiceElement, {
            scale: 4,  // Increase the scale for better quality
            useCORS: true,
            logging: true,
            height: invoiceElement.scrollHeight,
            windowWidth: invoiceElement.scrollWidth,
            windowHeight: invoiceElement.scrollHeight
        }).then((canvas) => {
            showButtons(invoiceElement);

            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'px',
                format: [canvas.width, canvas.height]  // Use the dimensions of the canvas
            });

            pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
            pdf.save(`${customerName}-invoice.pdf`);
        }).catch(error => {
            console.error('Error generating PDF:', error);
        });
    };

    const handlePrint = () => {
        if (!invoiceRef.current) return;

        const invoiceElement = invoiceRef.current;
        hideButtons(invoiceElement);

        html2canvas(invoiceElement, {
            scale: 4,  // Increase the scale for better quality
            useCORS: true,
            logging: true,
            height: invoiceElement.scrollHeight,
            windowWidth: invoiceElement.scrollWidth,
            windowHeight: invoiceElement.scrollHeight
        }).then((canvas) => {
            showButtons(invoiceElement);

            const imgData = canvas.toDataURL('image/png');
            const printWindow = window.open('', '', 'width=800,height=600');

            printWindow.document.open();
            printWindow.document.write(`
                <html>
                    <head>
                        <title>Print Invoice</title>
                        <style>
                            body { margin: 0; padding: 0; }
                            .invoice { width: 100%; }
                            img { width: 100%; }
                        </style>
                    </head>
                    <body>
                        <div class="invoice">
                            <img src="${imgData}" alt="Invoice"/>
                        </div>
                        <script>
                            window.onload = function() {
                                window.print();
                                window.onafterprint = function() {
                                    window.close();
                                };
                            };
                        </script>
                    </body>
                </html>
            `);
            printWindow.document.close();
        }).catch(error => {
            console.error('Error generating print content:', error);
        });
    };


    const handleWhatsApp = () => {
        if (!invoiceRef.current) return;

        const invoiceElement = invoiceRef.current;
        hideButtons(invoiceElement);  // Hide any buttons or unnecessary elements in the invoice
        setUploading(true);

        // Set the canvas height to the scrollHeight to capture the entire content
        html2canvas(invoiceElement, {
            scale: 1,
            useCORS: true,
            logging: true,
            height: invoiceElement.scrollHeight,  // Use scrollHeight to capture the full content
            windowWidth: invoiceElement.scrollWidth,
            windowHeight: invoiceElement.scrollHeight
        }).then((canvas) => {
            showButtons(invoiceElement);  // Show buttons again after capturing the invoice

            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'px',
                format: [canvas.width, canvas.height]
            });

            pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

            const pdfBlob = pdf.output('blob');
            const uniqueFileName = `{customerName}-${Date.now()}.pdf`;  // Generate a unique file name
            const file = new File([pdfBlob], uniqueFileName, { type: 'application/pdf' });

            return uploadPdfToS3(file);  // Upload the PDF to S3
        }).then((url) => {
            const formattedContactNumber = contactNumber.replace(/[^0-9]/g, '');  // Remove non-numeric characters
            const completeNumber = `+${formattedContactNumber}`;  // Ensure it starts with a '+'
            const token = localStorage.getItem('token');  // Get the token from localStorage

            const payload = {
                contactNumber: completeNumber,  // Phone number of the recipient in international format
                billUrl: url  // URL of the uploaded PDF
            };

            // Send the POST request
            return fetch('https://pos.farm2bag.com/api/v1/whatsapp/sendBill', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  // Include the Bearer token in the header
                },
                body: JSON.stringify(payload)  // Convert the payload to a JSON string
            });
        }).then(response => response.json())
            .then(data => {
                setUploading(false);  // Stop showing the loading spinner
                if (data.message === 'Message sent successfully.') {
                    toast.success('WhatsApp message sent successfully!', {
                        position: "top-right",
                        autoClose: 3000  // Auto-close after 3 seconds
                    });
                } else {
                    toast.error('Failed to send WhatsApp message.', {
                        position: "top-right",
                        autoClose: 3000  // Auto-close after 3 seconds
                    });
                    console.error('Error sending message:', data.whatsappResponse || 'Unknown error');
                }
            }).catch(error => {
                setUploading(false);  // Stop showing the loading spinner
                toast.error('Error uploading PDF or sending message.', {
                    position: "top-right",
                    autoClose: 3000  // Auto-close after 3 seconds
                });
                console.error('Error uploading PDF or sending message:', error);
            });
    };

    const hideButtons = (element) => {
        const buttons = element.querySelectorAll('button');
        buttons.forEach(button => {
            button.style.display = 'none';
        });
    };

    const showButtons = (element) => {
        const buttons = element.querySelectorAll('button');
        buttons.forEach(button => {
            button.style.display = '';
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Invoice"
            className="flex items-center justify-center"
            overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center"
        >
            <div ref={invoiceRef} className="bg-white p-3 rounded-lg shadow-lg w-96 max-h-screen overflow-y-auto">
                <div className="text-center mb-4">
                    <img src={f2b_logo} alt="Company Logo" className="mx-auto h-18 w-32" />
                    <h2 className="text-2xl font-extrabold mt-2 text-black">{warehouseName}</h2>
                    <p className="text-sm text-black font-semibold">Farm2bag, 5/396 , Rajeev Gandhi Salai, OMR Thoraipakkam , Chennai, Tamil Nadu 600097</p>
                    <p className='text-sm text-black font-semibold'>PH-095000 37221</p>
                </div>
                <h2 className="text-xl font-bold mb-4 text-center">Invoice</h2>
                <div className="mb-4">
                    <table className="min-w-full bg-white border-none">
                        <tbody>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Invoice Number:</th>
                                <td className="px-4 py-1 text-black font-semibold">{responseData.data.invoice || 'N/A'}</td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Date:</th>
                                <td className="px-2 py-1 text-black font-semibold">
                                    {responseData.data.invoiceDate || 'N/A'}
                                    <span className='ml-2'>{new Date(responseData.data.createdAt).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}</span>
                                </td>
                            </tr>
                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Customer Name:</th>
                                <td className="px-4 py-1 text-black font-semibold">{customerName || 'N/A'}</td>
                            </tr>

                            {deliveryCharge > 0 && (
                                <tr className="border-b">
                                    <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Shipping Charge:</th>
                                    <td className="px-4 py-1 text-black font-semibold">{currencyFormatter.format(deliveryCharge)}</td>
                                </tr>
                            )}
                            {discount > 0 && (
                                <tr className="border-b">
                                    <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Discount (%)</th>
                                    <td className="px-4 py-1 text-black font-semibold">{discount} %</td>
                                </tr>
                            )}
                            {selectedGiftPrice > 0 && (
                                <tr className="border-b">
                                    <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Gift Value</th>
                                    <td className="px-4 py-1 text-black font-semibold">₹ {selectedGiftPrice}</td>
                                </tr>
                            )}
                            {gst > 0 && (
                                <tr className="border-b">
                                    <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">GST:</th>
                                    <td className="px-4 py-1 text-black font-semibold">{gst} %</td>
                                </tr>
                            )}




                            <tr className="border-b">
                                <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Payment Mode:</th>
                                <td className="px-4 py-1 text-black font-semibold">{paymentMode || 'N/A'} / {responseData.data.status || 'N/A'}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <table className="min-w-full bg-white border border-gray-200">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className=" py-1 text-left text-sm font-bold text-black uppercase">Product</th>
                            <th className=" py-1 text-center text-sm font-bold text-black text-nowrap uppercase"> Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {responseData.data.billingItems && responseData.data.billingItems.map((item, index) => (
                            item.product.map((product, subIndex) => {
                                const matchedProductDetail = productDetails.find(detail => detail.id === product.productId);
                                return (
                                    <tr key={`${index}-${subIndex}`} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                                        {/* <td className=" py-1 text-wrap text-sm font-medium text-black truncate overflow-hidden" style={{ maxWidth: "350px" }}>{matchedProductDetail ? matchedProductDetail.name : 'N/A'} <br />
                                            ({product.quantity || 0} {product.unit || 'N/A'} x {matchedProductDetail ? currencyFormatter.format(matchedProductDetail.price) : '₹0.00'})</td> */}


                                        <td className="py-1 text-wrap text-sm font-medium text-black truncate overflow-hidden" style={{ maxWidth: "350px" }}>
                                            {matchedProductDetail ? matchedProductDetail.name : 'N/A'} <br />
                                            {matchedProductDetail && matchedProductDetail.price > 0 ? (
                                                `(${product.quantity || 0} ${product.unit || 'N/A'} x ${currencyFormatter.format(matchedProductDetail.price)})`
                                            ) : (
                                                `(${product.quantity || 0} ${product.unit || 'N/A'})`
                                            )}
                                        </td>



                                        <td className="py-1 pl-2 whitespace-nowrap text-sm text-black font-semibold">
                                            {product.totalPrice > 0 ? currencyFormatter.format(product.totalPrice) : <img src={Gift} alt="Gift" className="h-6 w-6" />}
                                        </td>
                                    </tr>
                                );
                            })
                        ))}
                        <tr className="bg-gray-100">


                            <td colSpan="1" className="px-1 py-1 whitespace-nowrap text-sm font-bold text-gray-900 text-right">Grand Total : </td>
                            <td className="px-1 py-1 whitespace-nowrap text-sm font-bold text-black ">

                                {/* ₹ {responseData.data.billingItems.reduce((total, item) =>
                                    selectedGiftPrice + total + item.product.reduce((subTotal, product) => subTotal + (product.totalPrice || 0), 0), 0).toFixed(2)} */}

₹ {(responseData.data.billingItems.reduce((total, item) =>
    total + (item.product || []).reduce((subTotal, product) =>
        subTotal + (typeof product.totalPrice === 'number' ? product.totalPrice : 0), 0), 0) + (selectedGiftPrice || 0)).toFixed(2)}

                            </td>
                        </tr>

                        <tr className="border-black border-2">
                            <th className="px-4 py-1 text-center text-black text-nowrap font-black pl-14">Net Payable : {totalAmount ? currencyFormatter.format(totalAmount) : 'N/A'}</th>
                        </tr>
                    </tbody>
                </table>

                <div className='text-center'>
                    <p className='text-lg font-extrabold'>Thank You For<span> Shopping With Us.</span><br /> Please vist again.</p>
                    <img src={f2bLink} alt="Company Logo" className="h-20 w-20 block mt-2 mx-auto" />
                </div>
                <div className='flex justify-between'>
                    <button
                        onClick={onClose}
                        className=" bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none"
                    > Close </button>
                    <button
                        onClick={handleDownloadPDF}
                        className=" bg-green-500 text-white h-10 p-2 rounded-lg hover:bg-green-600 focus:outline-none"
                    > Download PDF </button>
                    <button onClick={handleWhatsApp} className={` bg-green-500 text-white h-10 p-2 rounded-lg hover:bg-green-600 focus:outline-none${uploading ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={uploading}>
                        {uploading ? 'Uploading...' : 'WhatsApp'}
                    </button>
                    <button
                        onClick={handlePrint}
                        className=" bg-purple-500 text-white h-10 p-2 rounded-lg hover:bg-purple-600 focus:outline-none"
                    > Print </button>
                </div>
            </div>
        </Modal>
    );
};

export default InvoicePopup;
