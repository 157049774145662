import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const formatDate = (date) => {
  if (!date) return '';
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`; // Convert to `dd-MM-yyyy`
};


const parseDateSafely = (dateString) => {
  if (!dateString) return null;

  // Check if the date is in the format "dd-MM-yyyy"
  if (/^\d{1,2}-\d{1,2}-\d{4}$/.test(dateString)) {
    const [day, month, year] = dateString.split('-');
    return new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`);
  }

  // Check if the date is in the format "dd/MM/yyyy"
  if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`);
  }

  // Return null for unsupported formats
  return null;
};


const StockEdit = ({ isVisible, onClose, product, warehouseId, onProductUpdated }) => {

  const [newPrice, setNewPrice] = useState(product?.price || "");
  const [newQuantity, setNewQuantity] = useState(product?.quantity || "");
  const [mafDate, setMafDate] = useState(() => parseDateSafely(product?.maf_Date));
  const [expDate, setExpDate] = useState(() => parseDateSafely(product?.exp_Date));
  const [unitOptions, setUnitOptions] = useState([]);
  const [selectedUnitId, setSelectedUnitId] = useState(product?.unitId || "");
  const [purchasePrice, setPurchasePrice] = useState(product?.purchasePrice || "");
  const [margin, setMargin] = useState(product?.margin || "");
  const [transAndPackFees, setTransAndPackFees] = useState(product?.transAndPackFees || "");
  const [gstTax, setGstTax] = useState(product?.gstTax || "");
  const [productName, setProductName] = useState(product?.name || "");
  const [productsCode, setProductsCode] = useState(product?.productsCode || "");
  const [priceError, setPriceError] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [purchasePriceError, setPurchasePriceError] = useState("");
  const [marginError, setMarginError] = useState("");
  const [transAndPackFeesError, setTransAndPackFeesError] = useState("");
  const [gstTaxError, setGstTaxError] = useState("");
  const [gstToggle, setGstToggle] = useState("Without GST");



  useEffect(() => {
    calculatePrice(); // Recalculate price on relevant field changes
  }, [purchasePrice, margin, transAndPackFees, gstTax]);


  const calculatePrice = () => {
    const purchasePriceValue = parseFloat(purchasePrice) || 0;
    const marginValue = parseFloat(margin) || 0;
    const feesValue = parseFloat(transAndPackFees) || 0;
    const gstValue = parseFloat(gstTax) || 0;

    // Calculate `withoutGSTTotal`
    const withoutGSTTotal = (purchasePriceValue * (marginValue / 100)) + feesValue;

    // Calculate `gstWithPriceValue`
    const gstWithPriceValue = purchasePriceValue * (gstValue / 100);

    // Correct Final Price calculation
    const finalPrice = withoutGSTTotal + gstWithPriceValue + purchasePriceValue;

    setNewPrice(finalPrice.toFixed(2)); // Update `Price` state
  };

  // Fetch unit options when the component mounts
  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("Authentication token is missing");
          return;
        }

        const response = await fetch('https://pos.farm2bag.com/api/v1/measurements/master', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status === 200) {
            setUnitOptions(data.data);
          } else {
            console.error("Failed to fetch units");
          }
        } else {
          console.error("Error fetching units");
        }
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };

    fetchUnits();
  }, []);

  // Reset fields if product changes
  useEffect(() => {
    if (product) {
      // setNewPrice(product.price || "");
      setNewQuantity(product.quantity || "");
      setExpDate(parseDateSafely(product?.exp_Date));
      setMafDate(parseDateSafely(product?.maf_Date));
      setSelectedUnitId(product.unitId || "");
      setPurchasePrice(product.purchasePrice || "");
      setMargin(product.margin || "");
      setTransAndPackFees(product.transAndPackFees || "");
      setGstTax(product.gstTax || "");
      setProductName(product.name || "");
      setProductsCode(product.productsCode || "");
    }
  }, [product]);

  // Update product details
  const handleUpdateProduct = async () => {
    let isValid = true;

    if (newPrice === "" || newPrice < 0) {
      setPriceError(newPrice < 0 ? "Negative value is not allowed" : "Price is required");
      isValid = false;
    } else {
      setPriceError("");
    }

    if (newQuantity === "" || newQuantity < 0) {
      setQuantityError(newQuantity < 0 ? "Negative value is not allowed" : "Quantity is required");
      isValid = false;
    } else {
      setQuantityError("");
    }

    if (!isValid) return;

    // Set default values for empty fields
    const marginValue = margin || "0";
    const feesValue = transAndPackFees || "0";
    const gstValue = gstTax || "0";

    try {
      const token = localStorage.getItem("token");
      const role = localStorage.getItem('role');

      if (!token) {
        setPriceError("Authentication token is missing");
        return;
      }

      const url = new URL(`https://pos.farm2bag.com/api/v1/stocks/${warehouseId}/${product.productId}`);

      const params = new URLSearchParams({
        purchasePrice: purchasePrice.toString(),
        margin: marginValue.toString(), // Use default value
        transAndPackFees: feesValue.toString(), // Use default value
        gstTax: gstValue.toString(), // Use default value
        price: newPrice.toString(),
        quantity: newQuantity.toString(),
        exp_Date: expDate ? formatDate(expDate) : "",
        maf_Date: mafDate ? formatDate(mafDate) : "",
        unitId: selectedUnitId,
        modifiedBy: role
      });


      url.search = params.toString();

      const response = await fetch(url.toString(), {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        onClose();
        onProductUpdated();
      } else {
        const data = await response.json();
        setPriceError(data.message || "Failed to update product");
      }
    } catch (error) {
      console.error('Error updating product:', error);
      setPriceError("An unexpected error occurred");
    }
  };



  if (!isVisible) return null;

  return (


    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 overflow-y-auto mt-20">
      <div className="bg-gray-300 p-5 rounded-2xl shadow-2xl max-w-3xl w-full max-h-[90vh] overflow-y-auto">
        <h2 className="text-3xl font-extrabold text-blue-600 mb-6 text-center underline decoration-blue-400"> Update Product</h2>

        <h1 className="text-xl text-gray-800 mb-4 text-center font-semibold bg-blue-50 py-2 rounded-lg shadow-sm">{productName} <span className='text-red-800'>[ {productsCode} ]</span></h1>

        <div className="grid grid-cols-3 gap-6">

          {/* Purchase Price */}
          <div>
            <label className="block text-gray-600 font-medium mb-2">Purchase Price</label>
            <input
              type="number"
              value={purchasePrice}
              onChange={(e) => {
                const value = e.target.value;
                if (value >= 0) {
                  setPurchasePrice(value);
                  setPurchasePriceError("");
                } else {
                  setPurchasePriceError("Negative value is not allowed");
                }
              }}
              min="0"
              className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="Enter new price"
            />
            {priceError && <div className="text-red-500 mt-1">{priceError}</div>}

            {/* GST Toggle Button */}
            <button
              onClick={() => {
                if (gstToggle === "Without GST") {
                  setGstToggle("With GST");
                  setGstTax(""); // Reset GST input value if toggled to "With GST"
                } else {
                  setGstToggle("Without GST");
                }
              }}
              className={`mt-3 w-full px-4 py-2 text-white rounded-lg shadow transition duration-200 ${gstToggle === "Without GST" ? "bg-green-500 hover:bg-green-600" : "bg-blue-500 hover:bg-blue-600"
                }`}
            >
              {gstToggle}
            </button>
          </div>

          <div >
            <label className="block text-gray-600 font-medium mb-2">Margin</label>
            <input
              type="number"
              value={margin}
              onChange={(e) => {
                const value = e.target.value;
                if (value >= 0) {
                  setMargin(value);
                  setMarginError("");
                } else {
                  setMarginError("Negative value is not allowed");
                }
              }}
              min="0"
              className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="Enter new margin"
            />
            {priceError && <div className="text-red-500 mt-1">{priceError}</div>}
          </div>
          <div >
            <label className="block text-gray-600 font-medium mb-2">Transport & Packing Fees</label>
            <input
              type="number"
              value={transAndPackFees}
              onChange={(e) => {
                const value = e.target.value;
                if (value >= 0) {
                  setTransAndPackFees(value);
                  setTransAndPackFeesError("");
                } else {
                  setTransAndPackFeesError("Negative value is not allowed");
                }
              }}
              min="0"
              className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="Enter fees"
            />
            {priceError && <div className="text-red-500 mt-1">{priceError}</div>}
          </div>

          {/* GST Tax */}
          <div>
            <label className="block text-gray-600 font-medium mb-2">GST Tax</label>
            <input
              type="number"
              value={gstTax}
              onChange={(e) => {
                const value = e.target.value;
                if (value >= 0) {
                  setGstTax(value);
                  setGstTaxError("");
                } else {
                  setGstTaxError("Negative value is not allowed");
                }
              }}
              min="0"
              className={`w-full px-4 py-3 border rounded-lg focus:outline-none ${gstToggle === "With GST" ? "bg-gray-100 cursor-not-allowed" : "focus:border-blue-500"
                }`}
              placeholder="Enter GST tax"
              disabled={gstToggle === "With GST"}
            />
            {gstTaxError && <div className="text-red-500 mt-1">{gstTaxError}</div>}
          </div>

          <div>
            <label className="block text-gray-600 font-medium mb-2">Price (₹)</label>
            <input
              type="number"
              value={newPrice}
              readOnly
              className="w-full px-4 py-3 border rounded-lg bg-gray-100 cursor-not-allowed"
              placeholder="Calculated price"
            />
          </div>

          <div>
            <label className="block text-gray-600 font-medium mb-2">Quantity</label>
            <input
              type="number"
              value={newQuantity}
              onChange={(e) => {
                const value = e.target.value;
                if (value >= 0) {
                  setNewQuantity(value);
                  setQuantityError("");
                } else {
                  setQuantityError("Negative value is not allowed");
                }
              }}
              min="1"
              className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:border-blue-500"
              placeholder="Enter quantity"
            />
            {quantityError && <div className="text-red-500 mt-1">{quantityError}</div>}
          </div>

          <div>
            <label className="block text-gray-600 font-medium mb-2">Unit</label>
            <select
              value={selectedUnitId}
              onChange={(e) => setSelectedUnitId(e.target.value)}
              className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:border-blue-500"
            >
              <option value="" disabled>Select unit</option>
              {unitOptions.map((unit) => (
                <option key={unit.id} value={unit.id}>
                  {unit.unitSymbol}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-gray-600 font-medium mb-2">Manufacturing Date</label>
            <DatePicker
              selected={mafDate}
              onChange={setMafDate}
              dateFormat="dd-MM-yyyy"
              className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-600 font-medium mb-2">Expiration Date</label>
            <DatePicker
              selected={expDate}
              onChange={setExpDate}
              dateFormat="dd-MM-yyyy"
              className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
        </div>

        <div className="flex justify-between mt-4">
          <button
            onClick={onClose}
            className="px-6 py-3 bg-red-400 text-white rounded-lg hover:bg-red-500"
          >
            Cancel
          </button>
          <button
            onClick={handleUpdateProduct}
            className="px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700"
          >
            Update Product
          </button>
        </div>
      </div>
    </div>
  );
};

export default StockEdit;