import React, { useState, useEffect } from 'react';
import { parse, isSameDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, subWeeks, subMonths, isWithinInterval } from 'date-fns';
import * as XLSX from 'xlsx';

const SalesReport = () => {
  const [salesData, setSalesData] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const [selectedPaymentMode, setSelectedPaymentMode] = useState('all');
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState('All');
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFilterPaymentStatus, setSelectedFilterPaymentStatus] = useState("all");

  const fetchSalesData = async () => {
    try {
      const token = localStorage.getItem('token');
      setLoading(true);
      const response = await fetch('https://pos.farm2bag.com/api/v1/billing/getallForSales', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data && Array.isArray(data.data)) {
        const formattedData = data.data
          .map((item) => ({
            ...item,
            createdAt: new Date(item.billing.createdAt), // Convert createdAt string to Date object
          }))
          .sort((a, b) => b.createdAt - a.createdAt); // Sort in descending order by createdAt
        setSalesData(formattedData);
      } else {
        console.error('Unexpected sales data format:', data);
      }
    } catch (error) {
      console.error('Error fetching sales data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchWarehouseData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data && Array.isArray(data.data)) {
        setWarehouses(data.data);
      } else {
        console.error('Unexpected warehouse data format:', data);
      }
    } catch (error) {
      console.error('Error fetching warehouses:', error);
    }
  };

  useEffect(() => {
    fetchSalesData();
    fetchWarehouseData();
  }, []);
  const handleWarehouseChange = (e) => {
    setSelectedWarehouse(e.target.value);
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
  };

  const handlePaymentModeToggle = () => {
    if (selectedPaymentMode === "all") {
      setSelectedPaymentMode("cash");
    } else if (selectedPaymentMode === "cash") {
      setSelectedPaymentMode("card");
    } else if (selectedPaymentMode === "card") {
      setSelectedPaymentMode("digital");
    } else {
      setSelectedPaymentMode("all"); // Reset to 'all' after toggling through the modes
    }
  };


  const handlePaymentFilterStatusToggle = () => {
    if (selectedFilterPaymentStatus === "all") {
      setSelectedFilterPaymentStatus("Pending");
    } else if (selectedFilterPaymentStatus === "Pending") {
      setSelectedFilterPaymentStatus("Paid");
    } else {
      setSelectedFilterPaymentStatus("all");
    }
  };


  const applyDateFilter = (data, selectedDateRange, customStartDate, customEndDate) => {
    const today = new Date();

    switch (selectedDateRange) {
      case 'Today':
        return data.filter((item) =>
          isSameDay(parse(item.billing.invoiceDate, 'dd/MM/yyyy', new Date()), today)
        );
      case 'This Week':
        const startOfWeekDate = startOfWeek(today);
        return data.filter((item) =>
          isWithinInterval(parse(item.billing.invoiceDate, 'dd/MM/yyyy', new Date()), {
            start: startOfWeekDate,
            end: today,
          })
        );
      case 'Last Week':
        const startOfLastWeekDate = startOfWeek(subWeeks(today, 1));
        const endOfLastWeekDate = endOfWeek(subWeeks(today, 1));
        return data.filter((item) =>
          isWithinInterval(parse(item.billing.invoiceDate, 'dd/MM/yyyy', new Date()), {
            start: startOfLastWeekDate,
            end: endOfLastWeekDate,
          })
        );
      case 'This Month':
        const startOfMonthDate = startOfMonth(today);
        return data.filter((item) =>
          isWithinInterval(parse(item.billing.invoiceDate, 'dd/MM/yyyy', new Date()), {
            start: startOfMonthDate,
            end: today,
          })
        );
      case 'Last Month':
        const startOfLastMonthDate = startOfMonth(subMonths(today, 1));
        const endOfLastMonthDate = endOfMonth(subMonths(today, 1));
        return data.filter((item) =>
          isWithinInterval(parse(item.billing.invoiceDate, 'dd/MM/yyyy', new Date()), {
            start: startOfLastMonthDate,
            end: endOfLastMonthDate,
          })
        );
      case 'Custom Date':
        if (customStartDate && customEndDate) {
          return data.filter((item) =>
            isWithinInterval(parse(item.billing.invoiceDate, 'dd/MM/yyyy', new Date()), {
              start: customStartDate,
              end: customEndDate,
            })
          );
        }
        return data; // No filtering if custom dates are not set
      default:
        return data; // Return all data if no range is selected
    }
  };

  const handleDownloadExcel = () => {
    let filteredData = selectedWarehouse
      ? salesData.filter((item) => item.warehouseName === selectedWarehouse)
      : salesData;

    // Apply Payment Status and Mode Filters
    if (selectedPaymentStatus) {
      filteredData = filteredData.filter(
        (item) =>
          item.billing &&
          item.billing.status &&
          item.billing.status.toLowerCase() === selectedPaymentStatus.toLowerCase()
      );
    }

    if (selectedPaymentMode !== 'all') {
      filteredData = filteredData.filter(
        (item) => item.billing.paymentMode.toLowerCase() === selectedPaymentMode.toLowerCase()
      );
    }

    // Apply Date Filter
    filteredData = applyDateFilter(filteredData, selectedDateRange, customStartDate, customEndDate);

    if (filteredData.length === 0) {
      alert('No data available for download.');
      return;
    }

    const excelData = filteredData.map((item) => ({
      Invoice: item.billing.invoice,
      InvoiceDate: item.billing.invoiceDate,
      Customer: item.customer,
      Warehouse: item.warehouseName,
      PaymentMode:
        item.billing.paymentMode === 'digital' ? 'UPI' : item.billing.paymentMode.toUpperCase(),
      GrandTotal: item.billing.totalPrice,
      PaymentStatus: item.billing.status,
    }));

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sales Report');
    XLSX.writeFile(workbook, 'SalesReport.xlsx');
  };


  const renderTable = () => {
    let filteredData = selectedWarehouse
      ? salesData.filter((item) => item.warehouseName === selectedWarehouse)
      : salesData;

    // Apply Payment Status and Mode Filters
    if (selectedPaymentStatus) {
      filteredData = filteredData.filter(
        (item) =>
          item.billing &&
          item.billing.status &&
          item.billing.status.toLowerCase() === selectedPaymentStatus.toLowerCase()
      );
    }


    // Apply Payment Mode Filter
    if (selectedPaymentMode !== 'all') {
      filteredData = filteredData.filter(
        (item) => item.billing.paymentMode.toLowerCase() === selectedPaymentMode.toLowerCase()
      );
    }

    if (selectedFilterPaymentStatus !== 'all') {
      filteredData = filteredData.filter(
        (item) =>
          item.billing &&
          item.billing.status &&
          item.billing.status.toLowerCase() === selectedFilterPaymentStatus.toLowerCase()
      );
    }

    // Apply Date Filter
    filteredData = applyDateFilter(filteredData, selectedDateRange, customStartDate, customEndDate);

    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const currentItems = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    if (currentItems.length === 0) {
      return <div className="text-center py-4 text-gray-700">No data available for the selected filters.</div>;
    }

    return (
      <div className="bg-gray-100">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-blue-500">
            <tr>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">Invoice</th>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">Invoice Date</th>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">Customer</th>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">Warehouse</th>
              <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">
                PAYMENT MODE
                <button
                  onClick={handlePaymentModeToggle}
                  className="ml-2 text-white hover:text-gray-300 font-extrabold"
                >
                  ↓↑
                </button>
                <span className="text-sm ml-1">
                  (
                  {selectedPaymentMode === "all"
                    ? "All"
                    : selectedPaymentMode.charAt(0).toUpperCase() +
                    selectedPaymentMode.slice(1)}
                  )
                </span>
              </th>
              <th className="py-3 px-4 text-center text-xs font-medium text-white uppercase tracking-wider">Grand Total</th>
              <th className="py-3 px-4 text-left text-xs font-medium text-white uppercase tracking-wider">
                PAYMENT Status
                <button
                  onClick={handlePaymentFilterStatusToggle}
                  className="ml-2 text-white hover:text-gray-300 font-extrabold"
                >
                  ↓↑
                </button>
                <span className="text-sm ml-1">
                  (
                  {selectedFilterPaymentStatus === "all"
                    ? "All"
                    : selectedFilterPaymentStatus.charAt(0).toUpperCase() +
                    selectedFilterPaymentStatus.slice(1)}
                  )
                </span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {currentItems.map((item) => (
              <tr key={item.billing.id} className="hover:bg-green-200">
                <td className="py-3 px-5 border-b border-gray-300 font-semibold text-red-600 text-nowrap">{item.billing.invoice}</td>
                <td className="py-3 px-4 text-center">{item.billing.invoiceDate}</td>
                <td className="py-3 px-4 text-center">{item.customer}</td>
                <td className="py-3 px-4 text-center">{item.warehouseName}</td>
                <td className="py-3 px-4 text-left">
                  <span className="bg-yellow-200 border border-yellow-400 px-2 py-1  rounded font-bold">
                    {item.billing.paymentMode === 'digital' ? 'UPI' : item.billing.paymentMode.toUpperCase()}
                  </span>
                </td>
                <td className="py-3 px-4 text-center">₹{item.billing.totalPrice}</td>
                <td className="py-3 px-4 text-center">{item.billing.status}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="bg-gray-100 flex justify-between items-center mt-2 p-4 rounded-md shadow-md">
          <div>
            <label htmlFor="itemsPerPage" className="font-medium text-gray-800">Records per page: </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="border px-3 py-1 rounded-md text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-400"
            >
              {[10, 20, 50, 100].map((limit) => (
                <option key={limit} value={limit}>
                  {limit}
                </option>
              ))}
            </select>
          </div>

          <div className="text-sm font-medium text-gray-900">
            Page <span className="text-blue-600">{currentPage}</span> of <span className="text-blue-600">{totalPages}</span>
          </div>

          <div className="flex space-x-2">
            <button
              className={`border px-3 py-1 rounded-md transition duration-300 focus:outline-none 
                  ${currentPage === 1 ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600 focus:ring-2 focus:ring-blue-300'}`}
              onClick={() => handlePagination(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Prev
            </button>
            <button
              className={`border px-3 py-1 rounded-md transition duration-300 focus:outline-none 
                  ${currentPage === totalPages ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600 focus:ring-2 focus:ring-blue-300'}`}
              onClick={() => handlePagination(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>

      </div>
    );
  };


  return (
    <div className="px-4 py-6">
      <h2 className="font-extrabold text-4xl text-left text-teal-600">Sales Report</h2>

      <div className="flex space-x-4 my-4">
        {/* Warehouse Filter */}
        <select
          value={selectedWarehouse}
          onChange={handleWarehouseChange}
          className="p-2 border border-gray-300 rounded-md"
        >
          <option value="">Select Warehouse</option>
          {warehouses.map((warehouse) => (
            <option key={warehouse.id} value={warehouse.name}>
              {warehouse.name}
            </option>
          ))}
        </select>

        {/* Period Filter Dropdown */}
        <select
          value={selectedDateRange}
          onChange={(e) => setSelectedDateRange(e.target.value)}
          className="p-2 border border-gray-300 rounded-md"
        >
          <option value="All">All</option>
          <option value="Today">Today</option>
          <option value="This Week">This Week</option>
          <option value="This Month">This Month</option>
          <option value="Last Month">Last Month</option>
          <option value="Custom Date">Custom Date</option>
        </select>

        <button onClick={handleDownloadExcel} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
          Download Excel
        </button>

        {/* Custom Date Range Picker (Only visible if 'Custom Date' is selected) */}
        {selectedDateRange === 'Custom Date' && (
          <div className="flex space-x-4 my-4">
            <input
              type="date"
              value={customStartDate ? customStartDate.toISOString().split('T')[0] : ''}
              onChange={(e) => setCustomStartDate(new Date(e.target.value))}
              className="py-2 px-4 rounded-md border border-gray-300"
            />
            <input
              type="date"
              value={customEndDate ? customEndDate.toISOString().split('T')[0] : ''}
              onChange={(e) => setCustomEndDate(new Date(e.target.value))}
              className="py-2 px-4 rounded-md border border-gray-300"
            />
          </div>
        )}
      </div>

      {renderTable()}

    </div>
  );
};

export default SalesReport;