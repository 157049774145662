// import React, { useEffect, useState } from 'react';
// import ATM from '../Assets/Register/ATM.png';
// import Billion from '../Assets/Register/Billion-Doller.png';
// import MoneyHouse from '../Assets/Register/Money-House.png';
// import UPI from '../Assets/Register/UPI-Scan.png';

// const RegisterTotals = () => {
//   const [data, setData] = useState(null);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       const token = localStorage.getItem('token'); // Replace 'token' with the key used to store your token
//       if (!token) {
//         setError('No token found in local storage.');
//         return;
//       }

//       try {
//         const response = await fetch('https://pos.farm2bag.com/api/v1/registers/totals', {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${token}` // Assuming the token is used as a Bearer token
//           }
//         });

//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }

//         const result = await response.json();
//         setData(result.data);
//       } catch (err) {
//         setError(err.message);
//       }
//     };

//     fetchData();
//   }, []);

//   const formatCurrency = (value) => {
//     return new Intl.NumberFormat('en-IN', {
//         style: 'currency',
//         currency: 'INR',
//         minimumFractionDigits: 2,
//         maximumFractionDigits: 2
//     }).format(value);
// };

//   return (
//     <div>
//     {error && <p className="text-red-500 mb-4">Error: {error}</p>}
//     {data ? (
//       <div className="grid grid-cols-4 gap-4">
//         <div className="p-4 bg-gradient-to-r from-blue-500 to-purple-500 text-lg text-white transform hover:scale-105 transition-transform duration-300 rounded-lg flex items-center">
//           <img src={ATM} alt="Card Received" className="w-20 h-10 mr-4" />
//           <div>
//             Card  <br /> <span className="font-bold">{formatCurrency(data.cardReceived)}</span>
//           </div>
//         </div>
//         <div className="p-4 bg-gradient-to-r from-green-500 to-teal-500 text-lg text-white transform hover:scale-105 transition-transform duration-300 rounded-lg flex items-center">
//           <img src={UPI} alt="Digital Received" className="w-20 h-10 mr-4" />
//           <div>
//             UPI  <br /><span className="font-bold">{formatCurrency(data.digitalReceived)}</span>
//           </div>
//         </div>
//         <div className="p-4 bg-gradient-to-r from-red-500 to-pink-500 text-lg text-white transform hover:scale-105 transition-transform duration-300 rounded-lg flex items-center">
//           <img src={Billion} alt="Cash Received" className="w-20 h-10 mr-4" />
//           <div>
//             Cash  <br /><span className="font-bold">{formatCurrency(data.cashReceived)}</span>
//           </div>
//         </div>
//         <div className="p-4 bg-gradient-to-r from-orange-500 to-yellow-500 text-lg text-white transform hover:scale-105 transition-transform duration-300 rounded-lg flex items-center">
//           <img src={MoneyHouse} alt="Total Sales Amount" className="w-20 h-10 mr-4" />
//           <div>
//            Gross Sales  <br /><span className="font-bold">{formatCurrency(data.cardReceived + data.digitalReceived + data.cashReceived)}</span>
//           </div>
//         </div>
//       </div>
//     ) : (
//       <p>Loading...</p>
//     )}
//   </div>
// );
// };

// export default RegisterTotals;



import React, { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import ATM from '../Assets/Register/ATM.png';
import Billion from '../Assets/Register/Billion-Doller.png';
import MoneyHouse from '../Assets/Register/Money-House.png';
import UPI from '../Assets/Register/UPI-Scan.png';

const RegisterTotals = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No token found in local storage.');
        return;
      }

      try {
        const response = await fetch('https://pos.farm2bag.com/api/v1/registers/totals', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setData(result.data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, []);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  const AnimatedNumber = ({ value }) => {
    const { number } = useSpring({
      from: { number: 0 },
      to: { number: value },
      config: { duration: 2000 },
    });

    return (
      <animated.span>
        {number.to((n) => formatCurrency(n))}
      </animated.span>
    );
  };

  return (
    <div>
      {error && <p className="text-red-500 mb-4">Error: {error}</p>}
      {data ? (
        <div className="grid grid-cols-4 gap-4">
          <div className="p-4 bg-gradient-to-r from-blue-500 to-purple-500 text-lg text-white transform hover:scale-105 transition-transform duration-300 rounded-lg flex items-center">
            <img src={ATM} alt="Card Received" className="w-20 h-10 mr-4" />
            <div>
              Card <br /> <span className="font-bold"><AnimatedNumber value={data.cardReceived} /></span>
            </div>
          </div>
          <div className="p-4 bg-gradient-to-r from-green-500 to-teal-500 text-lg text-white transform hover:scale-105 transition-transform duration-300 rounded-lg flex items-center">
            <img src={UPI} alt="Digital Received" className="w-20 h-10 mr-4" />
            <div>
              UPI <br /><span className="font-bold"><AnimatedNumber value={data.digitalReceived} /></span>
            </div>
          </div>
          <div className="p-4 bg-gradient-to-r from-red-500 to-pink-500 text-lg text-white transform hover:scale-105 transition-transform duration-300 rounded-lg flex items-center">
            <img src={Billion} alt="Cash Received" className="w-20 h-10 mr-4" />
            <div>
              Cash <br /><span className="font-bold"><AnimatedNumber value={data.cashReceived} /></span>
            </div>
          </div>
          <div className="p-4 bg-gradient-to-r from-orange-500 to-yellow-500 text-lg text-white transform hover:scale-105 transition-transform duration-300 rounded-lg flex items-center">
            <img src={MoneyHouse} alt="Total Sales Amount" className="w-20 h-10 mr-4" />
            <div>
              Gross Sales <br />
              <span className="font-bold">
                <AnimatedNumber value={data.cardReceived + data.digitalReceived + data.cashReceived} />
              </span>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default RegisterTotals;
